import React, { memo } from "react";
import { CRow, CCard } from "@coreui/react";
import { ChartPieSimple, Spinner } from "../../atoms";
import styled from "styled-components";

const ChartPieCard = ({ titleLitte, title, footerText, dataPoints, labels, nTotal, loading }) => {
  return (
    <StyledCard className="p-4">
      <CRow>
        <h3 className="text-purple">{titleLitte}</h3>
        <div className="line-vertical" />
        <h4 className="pt-1 w-75">{title}</h4>
      </CRow>
      {loading ? (
        <div>
          <Spinner width={230} height="auto" />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <div className="chart-wrapper my-4 text-right">
          <h5>{nTotal && "TOTAL: " + nTotal}</h5>
          <ChartPieSimple style={{ height: "250px" }} dataPoints={dataPoints} labels={labels} />
        </div>
      )}
      <small>{footerText}</small>
    </StyledCard>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default memo(ChartPieCard);
