import { URL, buildRequest } from "../../config/config";

const getElementTabs = async (data) => {
  try {
    const request = await fetch(URL + "getElementTabs", buildRequest(data));
    const json = await request.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      message: error.message,
      code: error.code,
    };
  }
};

const getListVariables = async (data) => {
  try {
    const request = await fetch(URL + "getListVariables", buildRequest(data));
    const json = await request.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      message: error.message,
      code: error.code,
    };
  }
};

const updateVariable = async (data) => {
  try {
    const request = await fetch(URL + "updateVariable", buildRequest(data));
    const json = await request.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      message: error.message,
      code: error.code,
    };
  }
};

const registerVariable = async (data) => {
  try {
    const request = await fetch(URL + "registerVariable", buildRequest(data));
    const json = await request.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      message: error.message,
      code: error.code,
    };
  }
};

const getListDropdownVariable = async (data) => {
  let response = {
    success: false,
    list: [],
    code: "id-tab-null",
    message: "Categoría no dependiente",
  };
  try {
    if (data.idTab) {
      const request = await fetch(URL + "getListDropdownVariable", buildRequest(data));
      response = await request.json();
      return response;
    }
    return response;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      message: error.message,
      code: error.code,
    };
  }
};

const getRegions = async (data) => {
  let response = {
    success: false,
    regions: [],
    code: null,
    message: "No hay respuesta al obtener los municipios",
  };
  try {
    const responseFetch = await fetch(URL + "getRegions", buildRequest(data));
    const { success, regions, code, message } = await responseFetch.json();
    response = { success, regions, code, message };
    return response;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      message: error.message,
      code: error.code,
    };
  }
};

export { getListVariables, registerVariable, updateVariable, getElementTabs, getRegions, getListDropdownVariable };
