import React from "react";
import { CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CCardFooter } from "@coreui/react";
import { Spinner, Badge } from "../../atoms";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { ChartLineCard, ChartPieCard } from "../../molecules";
import mapDataHonduras from "../../../commons/consts/mapHonduras";
import { useSelector } from "react-redux";
import { elementsTabReports } from "../../../commons/static/tabs";
import { footerFontChartsText } from "../../../commons/static/tabs";
import { colorsBadged } from "../../../commons/consts/colors";
require("highcharts/modules/map")(Highcharts);

/* var dataDefault = [
  ["hn-at", 100000],
  ["hn-cl", 50000],
  ["hn-cm", 6000],
  ["hn-cp", 1000],
  ["hn-cr", 50],
  ["hn-ch", 100],
  ["hn-ep", 220],
  ["hn-fm", 210],
  ["hn-gd", 1000],
  ["hn-in", 5000],
  ["hn-ib", 1000],
  ["hn-lp", 1000],
  ["hn-le", 100],
  ["hn-oc", 200],
  ["hn-ol", 500],
  ["hn-sb", 300],
  ["hn-va", 110],
  ["hn-yo", 100],
]; */

const ComponentMap = ({ spinner, tabName, data, dataFemicides }) => {
  const { charts, total, maximo } = data;
  const theme = useSelector((state) => state.theme);
  const { weaponTypes, causePossible, rangeAgesFemicides } = dataFemicides;

  const mapOptions = {
    title: {
      text: "GEOREFERENCIA SOBRE CASOS DE FEMICIDIOS A NIVEL NACIONAL.",
      style: {
        fontWeight: "bold",
        color: theme === "light" ? "black" : "#ced2d8",
      },
    },
    mapNavigation: {
      enabled: true,
    },
    caption: {
      text: "Número de casos reportados en los medios de comunicación y registrados en el SAISV | Movimiento de Mujeres por la Paz Visitación Padilla",
      align: "right",
      style: { fontSize: 14, color: theme === "light" ? "black" : "#ced2d8" },
    },
    chart: {
      backgroundColor: theme === "light" ? "white" : "#474a55",
    },
    colorAxis: {
      min: 0,
      max: maximo,
      stops: [
        [0.2, "#F0EDED"],
        [0.4, "#3CDC09"],
        [0.6, "#FFC300"],
        [0.8, "#F5B924"],
        [1, "#D4240F"],
      ],
      labels: {
        style: { fontSize: 15, color: theme === "light" ? "black" : "#ced2d8" },
      },
    },
    legend: {
      borderRadius: 10,
      align: "right",
      padding: 15,
      title: {
        text: "Total de casos N=" + total,
        style: {
          fontSize: 14,
          fontWeight: "bold",
          color: theme === "light" ? "black" : "#ced2d8",
        },
      },
      itemMarginBottom: 10,
    },
    series: [
      {
        type: "map",
        mapData: mapDataHonduras,
        name: "Honduras",
        data: charts,
        dataLabels: {
          enabled: true,
          color: "#000000",
          formatter: function () {
            const value = this.point.value;
            const name = this.point.properties["postal-code"];
            return `${name}<br/>${value}`;
          },
          style: { fontSize: 12 },
        },
      },
    ],
  };

  return (
    <>
      {tabName === elementsTabReports[3].name && (
        <CContainer fluid>
          <CRow className="mt-5 justify-content-center align-items-center">
            {spinner ? (
              <div>
                <Spinner width={230} height="auto" />
                <p className="h4 font-regular text-center">Espere un momento por favor...</p>
              </div>
            ) : (
              <HighchartsReact
                options={mapOptions}
                constructorType={"mapChart"}
                highcharts={Highcharts}
                containerProps={{ className: "classMap" }}
              />
            )}
          </CRow>
          <CRow>
            <CCol xs="12">
              <ChartPieCard
                titleLitte="GRÁFICO 1"
                title="TIPOS DE ARMAS UTILIZADAS EN CASOS DE FEMICIDIOS"
                dataPoints={weaponTypes && weaponTypes.dataPoints}
                labels={weaponTypes && weaponTypes.labels}
                nTotal={weaponTypes && weaponTypes.total}
                loading={spinner}
                footerText={footerFontChartsText}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <ChartPieCard
                titleLitte="GRÁFICO 2"
                title="POSIBLES CAUSAS DE FEMICIDIOS REGISTRADOS"
                dataPoints={causePossible && causePossible.dataPoints}
                backgroundColor={causePossible ? colorsBadged(6) : colorsBadged(10)}
                labels={causePossible && causePossible.labels}
                nTotal={causePossible && causePossible.total}
                loading={spinner}
                footerText={footerFontChartsText}
              />
            </CCol>
          </CRow>

          <CCard className="border-0">
            <CCardHeader className="font-weight-bold h4 bg-gradient-blue text-white m-0">Rango en edades</CCardHeader>
            <CCardBody className={`px-5 py-4 ${theme === "dark" ? "bg-dark" : ""}`}>
              <CRow>
                <CCol>
                  <ChartLineCard
                    titleLitte="Gráfico 3"
                    title="EDADES DE LAS MUJERES VÍCTIMAS DE FEMICIDIOS"
                    dataPoints={rangeAgesFemicides.dataPoints}
                    labels={rangeAgesFemicides.labels}
                    label="EDADES"
                    nTotal={rangeAgesFemicides.total}
                    footerText={footerFontChartsText}
                    loading={spinner}
                    min={0}
                  />
                </CCol>
              </CRow>
              <CRow className="justify-content-center text-center">
                {rangeAgesFemicides.labels.map((item, index) => (
                  <CCol key={item} sm="6" md="4" lg="3" className="mb-3">
                    <div className="h5">{rangeAgesFemicides.dataPoints[index]}</div>
                    <Badge color={rangeAgesFemicides.colors[index]} className="w-100 p-2" label={item} icon={false} />
                  </CCol>
                ))}
              </CRow>
            </CCardBody>
            <CCardFooter className={`text-center ${theme === "dark" ? "bg-dark text-white" : "bg-light text-dark"}`}>
              Esta tabla ilustra el rango de las edades víctimas de femicidios.
            </CCardFooter>
          </CCard>
        </CContainer>
      )}
    </>
  );
};
export default ComponentMap;
