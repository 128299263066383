let onChangeSideBarShowNotifications = null;
let onChangeSideBarActiveModeDark = null;

const setToSideNavBar = (action, arg) => {
  switch (arg) {
    case "function-show-notifications":
      onChangeSideBarShowNotifications = action;
      break;
    case "function-active-mode-dark":
      onChangeSideBarActiveModeDark = action;
      break;
    default:
      break;
  }
};

const onHandleShowSideNotifications = () => {
  onChangeSideBarShowNotifications();
};

const onHandleActiveModedark = () => {
  onChangeSideBarActiveModeDark();
};

export {
  setToSideNavBar,
  onHandleShowSideNotifications,
  onHandleActiveModedark,
};
