import React from "react";
import PropTypes from "prop-types";
import { getColor, deepObjectsMerge } from "@coreui/utils";
import { CChart } from "@coreui/react-chartjs";
import { BackgroundColorsAreas } from "../../../commons/consts/colors";
import { useSelector } from "react-redux";

const ChartBarSimple = (props) => {
  const theme = useSelector((state) => state.theme);
  const { backgroundColor, dataPoints, label, pointed, nTotal, min, type, ...attributes } = props;

  const defaultDatasets = (() => {
    switch (type) {
      case "line":
        return [
          {
            data: dataPoints,
            label: label,
            backgroundColor: getColor(backgroundColor),
            hoverBackgroundColor: "rgb(4,210,252, 0.6)",
            barPercentage: 0.4,
            categoryPercentage: 1,
            borderWidth: 2,
            borderColor: "rgba(255,255,255,0.7)",
          },
        ];

      default:
        return [
          {
            data: dataPoints,
            backgroundColor: dataPoints[0] !== -1 ? getColor(BackgroundColorsAreas(dataPoints)) : "rgb(228,228,228)",
          },
        ];
    }
  })();

  // Opciones dataPoints = null.
  const pointedOptions = (() => {
    return {
      scales: {
        xAxes: [
          {
            display: type === "line" ? true : false,
            ticks: {
              fontSize: 17,
              fontColor: "white",
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            display: !pointed,
            ticks: {
              fontSize: 14,
              showLabelBackdrop: true,
              fontColor: theme === "light" ? "dark" : "white",
              min: min,
              max: Math.max(...dataPoints) + Math.ceil(Math.max(...dataPoints) * 0.2),
            },
            gridLines: {
              color: theme === "light" ? "rgb(235,237,239)" : "rgb(71,74,85)",
            },
          },
        ],
      },
    };
  })();

  const defaultOptions = (() => {
    return Object.assign({}, pointedOptions, {
      maintainAspectRatio: false,
      legend: {
        display: type === "line" ? !pointed : pointed,
        position: "left",
        labels: {
          fontSize: 14,
          fontColor: "white",
        },
      },
      tooltips:
        type === "line"
          ? {
              callbacks: {
                label: (tooltipItem) => {
                  const value = tooltipItem.yLabel;
                  const percentaje = ((value / nTotal) * 100).toFixed(1);
                  const tag = nTotal ? `${percentaje} %, casos = ${value}` : `casos = ${value}`;
                  return tag;
                },
                title: (tooltipItem, data) => {
                  return data.labels[tooltipItem[0].index];
                },
              },
            }
          : { enabled: true },
      animation: {
        duration: 1,
        onProgress: (animation) => {
          const chartInstance = animation.chart;
          const datasets = animation.chart.data.datasets;
          const ctx = animation.chart.ctx;

          ctx.textAlign = "center";
          ctx.fillStyle = theme === "light" && !pointed ? "rgba(0, 0, 0, 1)" : "rgba(255, 255, 255, 1)";
          ctx.textBaseline = "bottom";

          datasets.forEach((dataset, i) => {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              var data = dataset.data[index];
              if (type === "line") {
                ctx.fillText(data, bar._model.x, bar._model.y - 3);
              }
            });
          });
        },
      },
    });
  })();

  const computedDatasets = (() => {
    return deepObjectsMerge(defaultDatasets, attributes.datasets || {});
  })();

  const computedOptions = (() => {
    return deepObjectsMerge(defaultOptions, attributes.options || {});
  })();

  // render
  return <CChart type={type} {...attributes} datasets={computedDatasets} options={computedOptions} />;
};

ChartBarSimple.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  //
  backgroundColor: PropTypes.string,
  dataPoints: PropTypes.array,
  label: PropTypes.string,
  pointed: PropTypes.bool,
  type: PropTypes.string,
};

ChartBarSimple.defaultProps = {
  backgroundColor: "rgba(0,0,0,0.0)",
  dataPoints: [10, 22, 45, 46, 5, 100, 15, 16, 59, 84, 78, 12],
  labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
  label: "Sales",
  min: 0,
  type: "line",
};

export default ChartBarSimple;
