import React, { useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { Button } from "..";

const ChervonRight = () => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
  return (
    <div className="row align-items-center">
      <div className="col">
        <Button
          icon="chervonRight"
          color="info"
          className="rounded-lg"
          size="1x"
          disabled={isLastItemVisible}
          onClick={() => scrollNext()}
        />
      </div>
    </div>
  );
};

export default ChervonRight;
