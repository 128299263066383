// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";

//const URL = "http://localhost:5000/saisv-dev/us-central1/"; // local
//const URL = "http://localhost:5000/saisv-aa89e/us-central1/";
//const URL = "https://us-central1-saisv-dev.cloudfunctions.net/"; // development
const URL = "https://us-central1-saisv-aa89e.cloudfunctions.net/"; // Production

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const buildRequest = (data) => {
  return {
    method: "POST",
    mode: "cors",
    headers: new Headers({
      "Access-Control-Allow-Origin": URL,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
    }),
    body: JSON.stringify(data),
  };
};

// DEV
/* const firebaseConfig = {
  apiKey: "AIzaSyAvojbmJtlE5ug5Rk-P6myDX6qUfQYo9E8",
  authDomain: "saisv-dev.firebaseapp.com",
  projectId: "saisv-dev",
  storageBucket: "saisv-dev.appspot.com",
  messagingSenderId: "1049433485596",
  appId: "1:1049433485596:web:4c13d2caf03e7382b5cad9",
  measurementId: "G-X9Y22TZ6QM",
};
 */
// PROD
const firebaseConfig = {
  apiKey: "AIzaSyDUMJIEV8Ti7VUKVfPZikbixb07c4XVAFY",
  authDomain: "saisv-aa89e.firebaseapp.com",
  projectId: "saisv-aa89e",
  storageBucket: "saisv-aa89e.appspot.com",
  messagingSenderId: "494819504925",
  appId: "1:494819504925:web:e180ca949fd7b2ff4167f4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Storage
const storage = getStorage(app);
const getStorageRefPath = (path) => {
  const storageRef = ref(storage, path);
  return storageRef;
};

const upLoadImageBase64 = async (storageRef, fileRef) => {
  try {
    if (storageRef && fileRef) {
      const response = await uploadString(storageRef, fileRef, "base64");
      if (response) {
        return await getDownloadURL(storageRef);
      }
    } else {
      return {
        success: false,
        errorTitle: "Error",
        errorMessage: "Faltan más parametros para subir la imagen a base 64.",
      };
    }
  } catch (error) {
    return error;
  }
};

const resetToken = async () => {
  try {
    const response = await auth.currentUser.getIdToken(true);
    if (response) {
      return response;
    }
    return null;
  } catch (error) {}
};

export { app, auth, db, storage, URL, buildRequest, getStorageRefPath, upLoadImageBase64, resetToken };
