import React from "react";
import { CHeaderNav } from "@coreui/react";
import { NavLink, Icon } from "../../atoms";
import { useSelector } from "react-redux";
import { Offline } from "react-detect-offline";

const HeaderNav = ({ onClicklogout, onClickShowNotifications, onClickModeDark }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <CHeaderNav className="d-md-down-none ml-auto">
      <div className="mr-4">
        <Offline>
          <p className="h5 text-right font-semi-bold">
            Sin internet!
            <br />
            <i>
              <small className="text-danger">Por favor valida tu conexión de datos.</small>
            </i>
          </p>
        </Offline>
      </div>
      {/* <div className="position-relative">
        <small className="number-notifications">5</small>
        <NavLink
          className="bg-success rounded-circle"
          onClick={onClickShowNotifications}
          icon={<Icon name="bell" size="lg" className="text-white" />}
        />
      </div> */}
      <NavLink
        className={`rounded-circle mx-3 ${theme === "light" ? "bg-dark" : "bg-warning"}`}
        onClick={onClickModeDark}
        icon={<Icon name={theme === "light" ? "moon" : "sun"} size="lg" className="text-white" />}
      />
      <NavLink
        className="bg-danger rounded-circle mr-3"
        onClick={onClicklogout}
        icon={<Icon name="accountLogout" size="lg" className="text-white" />}
      />
    </CHeaderNav>
  );
};

export default HeaderNav;
