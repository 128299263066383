const variantsRP1 = {
  hidden: { opacity: 0, y: "15vh", transition: { duration: 1.8 } },
  visible: {
    opacity: 1,
    y: "0vh",
    transition: { duration: 1.5, type: "spring" },
  },
  exit: {
    opacity: 0,
    x: "-100vh",
    transition: { duration: 2 },
  },
};

export { variantsRP1 };
