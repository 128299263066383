import React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import { Tooltip } from "../../atoms";
// HELPERS
import { convertFormatTime } from "../../../commons/helpers/dates";

const buttonText = {
  today: "Hoy",
  week: "Semana",
  month: "Mes",
  day: "Día",
  list: "Lista",
};

const Calendar = ({ state, initialView, onChangeCalendar, handleEventClick }) => {
  const eventContent = ({ event }) => {
    const { extendedProps, title, backgroundColor, startStr, endStr } = event;
    const { description, attentionBy } = extendedProps;

    return (
      <Tooltip
        title={title}
        subTitle={`${convertFormatTime(startStr)} - ${convertFormatTime(endStr)}`}
        description={description}
        attentionBy={attentionBy}
        backgroundColor={backgroundColor}
      />
    );
  };

  const { events, date } = state;
  return (
    <div className="text-dark">
      <FullCalendar
        selectable
        //editable
        //droppable
        headerToolbar={{
          start: "prev,next today",
          center: "title prevYear nextYear",
          end: "timeGridDay,dayGridWeek,dayGridMonth",
        }}
        initialDate={date}
        dateClick={onChangeCalendar}
        buttonText={buttonText}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={initialView}
        titleFormat={{ year: "numeric", month: "short" }}
        events={events}
        locale="es"
        timeZone="UTC-6"
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }}
        dayMaxEventRows={4}
        views={{
          timeGrid: {
            dayMaxEventRows: 2, // adjust to 4 only for timeGridWeek/timeGridDay
          },
        }}
        eventContent={eventContent}
        //eventDrop={(e) => console.log("DROP: ", e)}
        eventClick={handleEventClick}
      />
    </div>
  );
};

export default Calendar;
