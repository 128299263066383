const INITIAL = {
  currentUser: {
    displayName: { username: "", id_rol: { value: -1, label: "No match" } },
  },
  showSideBar: true,
  theme: "light",
  showSideNotifications: false,
  loading: false,
  dateCurrent: "",
  filterCases: { limit: 10, pagination: 1, tableFilterValue: { name: "", startDate: "", endDate: "", userId: "" } },
  violenceTypes: [],
  sexualOrientations: [],
  maritalStatus: [],
  ethnicities: [],
  causes: [],
  aggressorTypes: [],
  weaponTypes: [],
  roles: [],
  charges: [],
  medios: [],
  states: [],
  attentionMode: [],
  therapeuticTechniques: [],
  affectationLevel: [],
  generalAsssessment: [],
  assignments: [],
  violenceAreas: [],
  victimRelationship: [],
  educationLevel: [],
  disabilityTypes: [],
  familyRights: [],
  crimes: [],
  support: [],
  caseReferred: [],
  virtualOptions: [],
  actionsPublic: [],
  accompaniments: [],
  dataReports: null,
  dataFemicidesDashboard: null,
  dataAreasAttentionDashboard: null,
  dataStatusCasesDashboard: null,
};

const Global = (state = INITIAL, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "SHOW_SIDEBAR":
      return {
        ...state,
        showSideBar: action.payload,
      };
    case "SET_DATE_CURRENT":
      return {
        ...state,
        dateCurrent: action.payload,
      };
    case "SET_SHOW_NOTIFICATIONS":
      return {
        ...state,
        showSideNotifications: action.payload,
      };
    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };
    case "SET_FILTER_CASES":
      return {
        ...state,
        filterCases: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_VARS_INITIALS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          idToken: action.payload,
        },
      };
    case "SET_DATA_REPORTS":
      return {
        ...state,
        dataReports: action.payload,
      };
    case "SET_FEMICIDES_DASHBOARD":
      return {
        ...state,
        dataFemicidesDashboard: action.payload,
      };
    case "SET_AREAS_ATTENTION_DASHBOARD":
      return {
        ...state,
        dataAreasAttentionDashboard: action.payload,
      };
    case "SET_STATUS_CASE_DASHBOARD":
      return {
        ...state,
        dataStatusCasesDashboard: action.payload,
      };
    default:
      return state;
  }
};

export default Global;
