import React from "react";
import { CBadge } from "@coreui/react";
import { colorsBadged } from "../../../commons/consts/colors";

const Badge = ({ label, color = "dark", type = "button", className, iconName, onClick, dependency, value, field }) => {
  return (
    <CBadge
      style={{
        backgroundColor: dependency ? colorsBadged(dependency.value) : "",
        fontSize: ".8rem",
      }}
      className={className}
      color={color}
      type={type}
      value={value}
      onClick={onClick}
    >
      {field === "accompanimentsReasons" ? (
        label
      ) : (
        <>
          {label.length > 34 ? label.substr(0, 34) + "..." : label} {iconName === "cancel" && "X"}
        </>
      )}
    </CBadge>
  );
};

export default Badge;
