import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowNotifications } from "../../../actionsCreators/Global";
import { CContainer } from "@coreui/react";
import { Button } from "../../atoms";
import { NotificationsTabs } from "../../molecules";
import DivTransition from "../../../commons/animations/DivTransition";
import { AnimatePresence } from "framer-motion";
import { variantsNotifications } from "../../../commons/consts/animations";
import styled from "styled-components";

const Notifications = () => {
  const dispatch = useDispatch();
  const showNotifications = useSelector((state) => state.showSideNotifications);
  const theme = useSelector((state) => state.theme);
  const onClose = () => {
    dispatch(setShowNotifications(false));
  };

  const handleScroll = () => {
    const mainNotificationsContainer = document.getElementById("scrollNotifications");
    if (mainNotificationsContainer) {
      if (window.scrollY > 870.0) {
        mainNotificationsContainer.style.paddingTop = "0px";
      } else {
        mainNotificationsContainer.style.paddingTop = "85px";
      }
    }
  };

  const willUnMount = useCallback(() => {
    window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return willUnMount;
  }, [willUnMount]);

  return (
    <AnimatePresence>
      {showNotifications && (
        <CContainer className="main-notifications">
          <div>
            <button className="bg-none vh-100 w-100 border-0 p-0 m-0 pt-5 mt-5" onClick={onClose} />
          </div>
          <DivTransition
            id="scrollNotifications"
            className="main-notifications-container"
            variants={variantsNotifications}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <StyledContainerNotifications>
              <div className="d-flex flex-row justify-content-end">
                <Button
                  icon="cancel"
                  color={theme === "dark" ? "light" : "dark"}
                  size="lg"
                  className="p-1 border-0"
                  onClick={onClose}
                  variant="ghost"
                />
              </div>
              <NotificationsTabs showNotifications />
            </StyledContainerNotifications>
          </DivTransition>
        </CContainer>
      )}
    </AnimatePresence>
  );
};

const StyledContainerNotifications = styled.div`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default Notifications;
