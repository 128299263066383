import React from "react";
import { Icon } from "..";
import PropTypes from "prop-types";

const LabelWarning = ({ text, iconName, iconColor }) => {
  return (
    <p className="p-0 m-0">
      <Icon name={iconName} color={iconColor} />
      <small className="text-orange"> {text} </small>
    </p>
  );
};

LabelWarning.propTypes = {
  text: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
};

LabelWarning.defaultProps = {
  text: "Ingrese la advertencia que desea mostrar...",
  iconName: "warning",
  iconColor: "#ecc95f",
};

export default LabelWarning;
