import { nameOrLastnameReg, numberIdentityReg, emailReg, phoneNumberHomeReg, phoneNumberReg, dateReg, ageReg } from "../consts/regularexp";

export const validationsUserForm = (fields, action, options) => {
  const {
    firstname,
    middlename,
    lastname,
    middlelastname,
    phone_cell1,
    phone_cell2,
    phone_house,
    address,
    birthday,
    digital_footprint,
    email,
    id_charge,
    id_rol,
    identity,
    username,
    password,
    photoURL,
  } = fields;
  const { dateCurrent } = options;
  let verify = { valid: true, message: "" };
  verify.message += validReExp(firstname, nameOrLastnameReg) ? "primer nombre, " : "";
  verify.message += validReExp(middlename, nameOrLastnameReg) ? "segundo nombre, " : "";
  verify.message += validReExp(lastname, nameOrLastnameReg) ? "primer apellido, " : "";
  verify.message += validReExp(middlelastname, nameOrLastnameReg) && middlelastname ? "segundo apellido, " : "";
  verify.message += validReExp(phone_cell1, phoneNumberReg) ? "celular 1, " : "";
  verify.message += validReExp(phone_cell2, phoneNumberReg) && phone_cell2 ? "celular 2, " : "";
  verify.message += validReExp(phone_house, phoneNumberHomeReg) && phone_house ? "teléfono casa, " : "";
  verify.message += !address ? "dirección o residencia, " : "";
  verify.message += validReExp(birthday, dateReg) || birthday > dateCurrent ? "nacimiento, " : "";
  verify.message += !digital_footprint ? "firma digital, " : "";
  verify.message += validReExp(email, emailReg) ? "correo, " : "";
  verify.message += !(id_charge.value !== -1) ? "cargo, " : "";
  verify.message += !(id_rol.value !== -1) ? "rol, " : "";
  verify.message += validReExp(identity, numberIdentityReg) ? "identidad, " : "";
  verify.message += !username ? "usuaria, " : "";
  // Unicamente si se crear una nueva usuaria, caso contrario si solo se actualizara.
  if (action === "Guardar") {
    verify.message += !(password.length > 5) ? "contraseña, " : "";
  }
  verify.message += !photoURL ? "foto de perfil." : "";
  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son incorrectos: " + verify.message;
  }
  return verify;
};

export const validationsFemicidesForm = (fields) => {
  const {
    firstname,
    secondname,
    lastname,
    secondlastname,
    age,
    occupation,
    sexualOrientationId,
    maritalStatusId,
    ethnicityId,
    // Medio
    medioId,
    weaponId,
    causeId,
    aggressorTypeId,
    violenceTypes,
    // Place
    headline,
    resumen,
    date,
    time,
    stateId,
    regionId,
    place,
    zone,
    pregnancy,
  } = fields;
  let verify = { valid: true, message: "" };
  verify.message += validReExp(firstname, nameOrLastnameReg) ? "primer nombre, " : "";
  verify.message += validReExp(secondname, nameOrLastnameReg) && secondname ? "segundo nombre, " : "";
  verify.message += validReExp(lastname, nameOrLastnameReg) ? "primer apellido, " : "";
  verify.message += validReExp(secondlastname, nameOrLastnameReg) && secondlastname ? "segundo apellido, " : "";
  verify.message += validReExp(age, ageReg) ? "edad, " : "";
  verify.message += occupation.label === "" ? "ocupación, " : "";
  verify.message += sexualOrientationId.value === -1 ? "género, " : "";
  verify.message += maritalStatusId.value === -1 ? "estado civil, " : "";
  verify.message += ethnicityId.value === -1 ? "étnia, " : "";
  verify.message += !medioId.items.length ? "medio de comunicación, " : "";
  verify.message += weaponId.value === -1 ? "tipo de arma, " : "";
  verify.message += !aggressorTypeId.length ? "tipo de agresor, " : "";
  verify.message += stateId.value === -1 ? "departamento, " : "";
  verify.message += regionId.value === -1 ? "municipio, " : "";
  verify.message += !violenceTypes.itemsSelected.length ? "tipos de violencia, " : "";
  verify.message += causeId.value === -1 ? "posible movil, " : "";
  verify.message += zone.value === -1 ? "zona, " : "";
  verify.message += pregnancy.value === -1 ? "embarazo, " : "";
  verify.message += !headline ? "titular, " : "";
  verify.message += !resumen ? "resumen noticioso, " : "";
  verify.message += !date ? "fecha, " : "";
  verify.message += !time ? "hora, " : "";
  verify.message += !place ? "lugar." : "";

  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son incorrectos: " + verify.message;
  }
  return verify;
};

export const validationsEmotionalForm = (paraments) => {
  let verify = { valid: true, message: "" };
  const {
    date,
    hour,
    names,
    lastnames,
    age,
    exposition,
    developmentSession,
    agreements,
    affectationLevel,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    violencesTypes,
    violencesApproaches,
    violenceAreas,
    generalAsssessment,
    therapeuticTechniques,
    otherTherapeuticTechniques,
    observations,
    attentionModeEmotional,
    reasonQuery,
    othersViolenceArea,
    virtualOptionsEmotional,
  } = paraments;
  verify.message += validReExp(date, dateReg) ? "fecha, " : "";
  verify.message += !hour ? "hora, " : "";
  verify.message += validReExp(names, nameOrLastnameReg) ? "nombres, " : "";
  verify.message += validReExp(lastnames, nameOrLastnameReg) ? "apellidos, " : "";
  verify.message += validReExp(age, ageReg) ? "edad, " : "";
  verify.message += !exposition ? "exposición del caso, " : "";
  verify.message += !reasonQuery ? "exposición del caso, " : "";
  verify.message += !developmentSession ? "desarrollo de la sesión, " : "";
  verify.message += !agreements ? "acuerdos finales, " : "";
  verify.message += validReExp(nextQuoteDate, dateReg) ? "fecha de próxima cita, " : "";
  verify.message += validHoursQuote(nextQuoteHourStart, nextQuoteHourEnd) ? "horas de próxima cita, " : "";
  verify.message += !(violencesTypes.itemsSelected.length > 0) ? "tipos de violencias existentes en el caso, " : "";
  verify.message += !(violencesApproaches.itemsSelected.length > 0) ? "tipos de violencias abordadas en el caso, " : "";
  verify.message += violenceAreas.length === 0 ? (!othersViolenceArea ? "áreas de afectación por violencias, " : "") : "";
  verify.message += affectationLevel.value === -1 ? "nivel de afectación por violencias, " : "";
  verify.message += !generalAsssessment ? "impresión general del caso, " : "";
  verify.message += !(therapeuticTechniques.items.length > 0)
    ? !otherTherapeuticTechniques
      ? "técnicas terapéuticas utilizadas, "
      : ""
    : "";
  verify.message += !observations ? "observaciones, " : "";
  verify.message += attentionModeEmotional === -1 ? "modalidad, " : "";
  verify.message +=
    attentionModeEmotional.value === "R925ema5ejFwt1y4HICn" && virtualOptionsEmotional.length === 0 ? "tipo de modalidad virtual." : "";
  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son necesarios: " + verify.message;
  }
  return verify;
};

export const validationsLegalForm = (paraments) => {
  let verify = { valid: true, message: "" };
  const {
    dateAreaLegal,
    hourAreaLegal,
    identity,
    age,
    tutor,
    ethnicities,
    maritalStatus,
    occupation,
    educationLevel,
    address,
    phoneNumber,
    phoneHouse,
    callEmergency,
    sexualOrientations,
    disability,
    disabilityType,
    aggressorNames,
    aggressorLastnames,
    aggressorAge,
    aggressorPhoneNumber,
    aggressorPhoneWork,
    aggressorPhoneHouse,
    aggressorEthnicity,
    aggressorEducationLevel,
    aggressorOccupation,
    aggressorAddress,
    aggressorType,
    aggressorViolenceTypes,
    familyRights,
    otherFamilyRights,
    crimes,
    otherCrime,
    description,
    support,
    otherSupport,
    caseReferred,
    otherReferredOgWomen,
    otherReferred,
    observationsLegal,
    recommendationsLegal,
    caseActions,
    virtualOptionsLegal,
    attentionModeLegal,
    digitalFootprint,
    usersAccess,
  } = paraments;

  verify.message += validReExp(dateAreaLegal, dateReg) ? "fecha de próxima cita, " : "";
  verify.message += !hourAreaLegal ? "hora de próxima cita, " : "";
  verify.message += validReExp(identity, numberIdentityReg) ? "identidad, " : "";
  verify.message += validReExp(age, ageReg) ? "edad de víctima, " : "";
  if (age <= 18) {
    verify.message += validReExp(tutor.names, nameOrLastnameReg) ? "nombres del tutor(a), " : "";
    verify.message += validReExp(tutor.lastnames, nameOrLastnameReg) ? "apellidos del tutor(a), " : "";
    verify.message += validReExp(tutor.identity, numberIdentityReg) ? "identidad del tutor(a), " : "";
    verify.message += validReExp(tutor.numberPhone, phoneNumberReg) ? "teléfono del tutor(a), " : "";
    verify.message += tutor.victimRelationship.value === -1 ? "parentesco de tutor(a), " : "";
  }
  verify.message += ethnicities.value === -1 ? "etnia, " : "";
  verify.message += maritalStatus.value === -1 ? "estado civil, " : "";
  verify.message += !occupation ? "profesión u oficio, " : "";
  verify.message += educationLevel.value === -1 ? "nivel educativo, " : "";
  verify.message += !address ? "dirección / residencia, " : "";
  verify.message += validReExp(phoneNumber, phoneNumberReg) ? "número de celular, " : "";
  verify.message += phoneHouse.length === 0 ? "" : validReExp(phoneHouse, phoneNumberHomeReg) ? "teléfono, " : "";
  verify.message += !callEmergency.nameComplete ? "nombre completo en caso de emergencia, " : "";
  verify.message += validReExp(callEmergency.phoneNumber, phoneNumberReg) ? "teléfono celular en caso de emergencia, " : "";
  verify.message +=
    callEmergency.phoneWork.length === 0
      ? ""
      : validReExp(callEmergency.phoneWork, phoneNumberHomeReg)
      ? "teléfono de trabajo en caso de emergencia, "
      : "";
  verify.message += !callEmergency.address ? "dirección en caso de emergencia, " : "";
  verify.message += sexualOrientations.value === -1 ? "orientación sexual, " : "";
  verify.message += disability.value === -1 ? "discapacidad, " : "";
  if (disability.value === 1) {
    verify.message += disabilityType.value === -1 ? "tipo de discapacidad, " : "";
  }
  verify.message += validReExp(aggressorNames, nameOrLastnameReg) ? "nombres del agresor, " : "";
  verify.message += validReExp(aggressorLastnames, nameOrLastnameReg) ? "apellidos del agresor, " : "";
  verify.message += validReExp(aggressorAge, ageReg) || aggressorAge === 0 ? "edad del agresor, " : "";
  verify.message +=
    aggressorPhoneNumber.length === 0 ? "" : validReExp(aggressorPhoneNumber, phoneNumberReg) ? "teléfono celular del agresor, " : "";
  verify.message +=
    aggressorPhoneHouse.length === 0 ? "" : validReExp(aggressorPhoneHouse, phoneNumberHomeReg) ? "teléfono casa del agresor, " : "";
  verify.message +=
    aggressorPhoneWork.length === 0 ? "" : validReExp(aggressorPhoneWork, phoneNumberHomeReg) ? "teléfono trabajo del agresor, " : "";
  verify.message += aggressorEthnicity.value === -1 ? "etnia del agresor, " : "";
  verify.message += aggressorEducationLevel.value === -1 ? "nivel educativo del agresor, " : "";
  verify.message += !aggressorOccupation ? "profesión del agresor, " : "";
  verify.message += !aggressorAddress ? "dirección del agresor, " : "";
  verify.message += aggressorType.length === 0 ? "parentesco(s) con el agresor, " : "";
  verify.message += aggressorViolenceTypes.itemsSelected.length === 0 ? "violencias cometidas por agresor, " : "";
  verify.message += familyRights.length === 0 ? (!otherFamilyRights ? "derechos de familia, " : "") : "";
  verify.message += crimes.length === 0 ? (!otherCrime ? "delitos, " : "") : "";
  verify.message += !description ? "descripción, " : "";
  verify.message += support.length === 0 ? (!otherSupport ? "apoyo brindado, " : "") : "";
  verify.message += !observationsLegal ? "observaciones, " : "";
  verify.message += caseReferred.length === 0 ? (!otherReferredOgWomen ? (!otherReferred ? "caso remitido, " : "") : "") : "";
  verify.message += !recommendationsLegal ? "recomendaciones, " : "";
  verify.message += !caseActions ? "acciones a realizar, " : "";
  verify.message += attentionModeLegal.value === -1 ? "modalidad de atención, " : "";
  verify.message += !usersAccess.length ? "acceso a las colaboradoras, " : "";
  verify.message += !digitalFootprint ? "firma digital." : "";
  verify.message +=
    attentionModeLegal.value === "R925ema5ejFwt1y4HICn" && virtualOptionsLegal.length === 0 ? "tipo de modalidad virtual." : "";

  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son necesarios: " + verify.message;
  }
  return verify;
};

export const validationsFollowForm = (paraments) => {
  let verify = { valid: true, message: "" };
  const {
    dateFollow,
    hourFollow,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    crimesFollow,
    otherCrimes,
    descriptionFollow,
    supportFollow,
    otherSupportFollow,
    attentionModeFollow,
    virtualOptionsFollow,
    progressFollow,
  } = paraments;

  verify.message += validReExp(dateFollow, dateReg) ? "fecha, " : "";
  verify.message += !hourFollow ? "hora, " : "";
  verify.message += validReExp(nextQuoteDate, dateReg) ? "fecha próxima cita, " : "";
  verify.message += validHoursQuote(nextQuoteHourStart, nextQuoteHourEnd) ? "horas de próxima cita, " : "";
  verify.message += crimesFollow.items.length === 0 ? (!otherCrimes ? "delitos denunciados, " : "") : "";
  verify.message += !descriptionFollow ? "descripción, " : "";
  verify.message += supportFollow.length === 0 ? (!otherSupportFollow ? "apoyo brindado, " : "") : "";
  verify.message += attentionModeFollow.value === -1 ? "modalidad de atención, " : "";
  verify.message +=
    attentionModeFollow.value === "R925ema5ejFwt1y4HICn" && virtualOptionsFollow.length === 0 ? "tipo de modalidad virtual, " : "";
  verify.message += !(0 <= progressFollow && progressFollow <= 100) ? "nivel de progreso." : "";

  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son necesarios: " + verify.message;
  }
  return verify;
};

export const validationsVariablesForm = (fields, options) => {
  let verify = { valid: true, message: "" };
  const { name, dependency } = fields;
  verify.message = !name ? "Nombre de variable no existe o debe iniciar con mayuscula." : "";
  if (options.dependet) {
    verify.message = !(dependency.value !== -1) ? "Seleccione la variable independiente." : "";
  }
  if (verify.message) {
    verify.valid = false;
  }
  return verify;
};

export const validationsAccompaniemtForm = (paraments) => {
  let verify = { valid: true, message: "" };
  const {
    dateAccompaniment,
    hourAccompaniment,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    observationsAccompaniment,
    actionsPublic,
    otherActionPublic,
    justiceOperators,
    incidentsJusticeOperators,
    accompanimentsReasons,
    progressAccompaniment,
  } = paraments;
  verify.message += validReExp(dateAccompaniment, dateReg) ? "fecha, " : "";
  verify.message += !hourAccompaniment ? "hora, " : "";
  verify.message += validReExp(nextQuoteDate, dateReg) ? "fecha de próxima cita, " : "";
  verify.message += validHoursQuote(nextQuoteHourStart, nextQuoteHourEnd) ? "horas de próxima cita, " : "";
  verify.message += !observationsAccompaniment ? "observaciones, " : "";
  verify.message += actionsPublic.length === 0 ? (!otherActionPublic ? "acciones a realizar, " : "") : "";
  verify.message += justiceOperators.value === -1 ? "incidencias con operadores de justicia, " : "";
  verify.message +=
    justiceOperators.value === "1" && !incidentsJusticeOperators ? "justificación sobre la incidencia con operadores de justicia, " : "";
  verify.message += accompanimentsReasons.itemsSelected.length === 0 ? "lugares y motivos." : "";
  verify.message += !(0 <= progressAccompaniment && progressAccompaniment <= 100) ? "nivel de progreso." : "";
  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son requeridos: " + verify.message;
  }
  return verify;
};

export const validationsTestAppQuoteForm = (paraments) => {
  let verify = { valid: true, message: "" };
  const {
    dateTestApp,
    hourTestApp,
    queryReasonTestApp,
    appliedTests,
    observationsTestApp,
    resultsTestApp,
    attentionModeTestApp,
    virtualOptionsTestApp,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
  } = paraments;
  verify.message += validReExp(dateTestApp, dateReg) ? "fecha, " : "";
  verify.message += !hourTestApp ? "hora, " : "";
  verify.message += !queryReasonTestApp ? "motivo de consulta, " : "";
  verify.message += !appliedTests ? "pruebas aplicadas, " : "";
  verify.message += !observationsTestApp ? "observaciones, " : "";
  verify.message += !resultsTestApp ? "resultados obtenidos, " : "";
  verify.message += validReExp(nextQuoteDate, dateReg) ? "fecha de próxima cita, " : "";
  verify.message += validHoursQuote(nextQuoteHourStart, nextQuoteHourEnd) ? "horas de próxima cita, " : "";
  verify.message += attentionModeTestApp.value === -1 ? "modalidad de atención, " : "";
  verify.message +=
    attentionModeTestApp.value === "R925ema5ejFwt1y4HICn" && virtualOptionsTestApp.length === 0 ? "tipo de modalidad virtual." : "";

  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son requeridos: " + verify.message;
  }
  return verify;
};

export const validationsQuoteFollowForm = (paraments) => {
  let verify = { valid: true, message: "" };
  const {
    dateQuoteFollow,
    hourQuoteFollow,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    feedBack,
    therapeuticCompliance,
    developmentAttention,
    violencesApproachesQuoteFollow,
    affectationLevelQuoteFollow,
    generalAsssessmentQuoteFollow,
    therapeuticTechniquesQuoteFollow,
    attentionModeQuoteFollow,
    virtualOptionQuoteFollow,
    progressQuoteFollow,
  } = paraments;

  verify.message += validReExp(dateQuoteFollow, dateReg) ? "fecha, " : "";
  verify.message += validReExp(nextQuoteDate, dateReg) ? "fecha de próxima cita, " : "";
  verify.message += !hourQuoteFollow ? "hora, " : "";
  verify.message += validHoursQuote(nextQuoteHourStart, nextQuoteHourEnd) ? "hora de próxima cita, " : "";
  verify.message += !feedBack ? "retroalimentación, " : "";
  verify.message += therapeuticCompliance.value === -1 ? "cumplimiento de las asignaciones terapéuticas, " : "";
  verify.message += !violencesApproachesQuoteFollow.itemsSelected.length ? "tipo de violencias abordadas, " : "";
  verify.message += !developmentAttention ? "desarrollo de la atención, " : "";
  verify.message += generalAsssessmentQuoteFollow.value === -1 ? "nivel de afectación por violencias, " : "";
  verify.message += affectationLevelQuoteFollow.value === -1 ? "valoración general del caso, " : "";
  verify.message += !therapeuticTechniquesQuoteFollow.items.length ? "técnicas terapéuticas utilizadas, " : "";
  verify.message += !(0 <= progressQuoteFollow && progressQuoteFollow <= 100) ? "progreso, " : "";
  verify.message += attentionModeQuoteFollow.value === -1 ? "modalidad de atención, " : "";
  verify.message +=
    attentionModeQuoteFollow.value === "R925ema5ejFwt1y4HICn" && virtualOptionQuoteFollow.length === 0 ? "tipo de modalidad virtual." : "";

  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son requeridos: " + verify.message;
  }
  return verify;
};

export const validationsMultimedias = (paraments) => {
  let verify = { valid: true, message: "" };
  const { data } = paraments;
  verify.message += !(data.length > 0) ? "no hay archivos a registrar." : "";
  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son requeridos: " + verify.message;
  }
  return verify;
};

export const validationsTherapeuticPlanForm = (paraments) => {
  let verify = { valid: true, message: "" };
  const { dataEmotional } = paraments;
  verify.message += !(dataEmotional.length > 0) ? "no hay archivos a registrar." : "";
  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguientes campos son requeridos: " + verify.message;
  }
  return verify;
};

export const validationTypeFile = (typeSource) => {
  let verify = { valid: true, message: "" };
  const typeFile = typeSource.split("/")[0];
  const formatImages = ["image/jpeg", "image/png", "image/web", "image/gif"];
  const formatVideos = ["video/mp4", "video/3gp"];
  const formatAudios = ["audio/mpeg", "audio/ogg", "audio/aac"];
  const formatDocuments = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  switch (typeFile) {
    case "image":
      if (
        typeSource !== formatImages[0] &&
        typeSource !== formatImages[1] &&
        typeSource !== formatImages[2] &&
        typeSource !== formatImages[3]
      ) {
        verify.valid = false;
        verify.message = "Archivo de imagen no valido, permitidos los formatos PNG, JPEG, WEB y GIF.";
      }

      break;
    case "video":
      if (typeSource !== formatVideos[0] && typeSource !== formatVideos[1]) {
        verify.valid = false;
        verify.message = "Archivo de video no valido, permitidos los formatos MP4 y 3GP.";
      }
      break;
    case "audio":
      if (typeSource !== formatAudios[0] && typeSource !== formatAudios[1] && typeSource !== formatAudios[2]) {
        verify.valid = false;
        verify.message = "Archivo de audio no valido, permitidos los formatos MP3, OGG y AAC.";
      }
      break;
    default:
      if (typeSource !== formatDocuments[0] && typeSource !== formatDocuments[1] && typeSource !== formatDocuments[2]) {
        verify.valid = false;
        verify.message = "Archivo documento no valido, permitido solo archivos PDF, DOC y DOCX.";
      }
      break;
  }
  return verify;
};

export const validationResourcesUsed = (paraments) => {
  let verify = { valid: true, message: "" };
  const { resources } = paraments;
  verify.message += resources.items.length === 0 ? "no tiene recursos utilizados." : "";
  if (verify.message) {
    verify.valid = false;
    verify.message = "Los siguentes campos son requeridos: " + verify.message;
  }
  return verify;
};

// Functions Regular Expresions
// Colorear border de inputs
export const validInputReExp = (str, regExp) => {
  let re = new RegExp(regExp);
  if (str) {
    return re.test(str) ? "border-success" : "border-danger";
  } else {
    return "border-none";
  }
};

const validReExp = (str, regExp) => {
  let re = new RegExp(regExp);
  return !re.test(str);
};

const validHoursQuote = (start, end) => {
  if (start > end || start === end) {
    return true;
  } else if (start >= "07:00" && start <= "16:00" && end >= "08:00" && end <= "17:00") {
    return false;
  }
  return true;
};

export const verifyTokenExpired = (code) => {
  // return code === "auth/id-token-expired" || code === "auth/argument-error";
  return code === "auth/id-token-expired";
};
