import React from "react";
import { Icon } from "../../../components/atoms";
import { urlsDigitizer, urlsAdmin } from "../../consts/urls";
import {
  onHandleShowSideNotifications,
  onHandleActiveModedark,
} from "../../../config/user";
import { auth } from "../../../config/config";
import { signOut } from "firebase/auth";

// Navegacion para el digitador.
const navAdmin = [
  {
    _tag: "CSidebarNavItem",
    name: "Inicio",
    to: urlsAdmin.dashboard,
    icon: <Icon name="home" sideMenu />,
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Casos registrados",
    icon: <Icon name="briefcase" sideMenu />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Expedientes",
        to: urlsAdmin.care_records,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Femicidios",
        to: urlsAdmin.femicides,
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Usuarias",
    to: urlsAdmin.users,
    icon: <Icon name="userFemale" sideMenu />,
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavItem",
    name: "Evaluaciones",
    to: urlsAdmin.evaluations,
    icon: <Icon name="speedometer" sideMenu />,
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Variables",
    icon: <Icon name="pencil" sideMenu />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Área emocional",
        to: urlsAdmin.area_emotional,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Área legal",
        to: urlsAdmin.area_legal,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Globales",
        to: urlsAdmin.globals,
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Reportes",
    to: urlsAdmin.reports,
    icon: <Icon name="spreadsheet" sideMenu />,
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Opciones",
    icon: <Icon name="settings" sideMenu />,
    className: "d-md-block d-lg-none",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Notificaciones",
        className: "position-relative",
        icon: (
          <div>
            <Icon name="bell" size="lg" className="mr-2" />
            <small className="number-notifications number-notifications-menu-vertical">
              5
            </small>
          </div>
        ),
        onClick: onHandleShowSideNotifications,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Modo oscuro",
        icon: <Icon name="moon" size="lg" className="mr-2" />,
        onClick: onHandleActiveModedark,
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Cerrar sesión",
    className: "d-lg-none",
    icon: <Icon name="accountLogout" sideMenu />,
    onClick: () => logOut(),
  },
];

const navDigitizer = [
  {
    _tag: "CSidebarNavItem",
    name: "Inicio",
    to: urlsDigitizer.dashboard,
    icon: <Icon name="home" sideMenu />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Femicidios",
    to: urlsDigitizer.femicides,
    icon: <Icon name="speedometer" sideMenu />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Expedientes",
    to: urlsDigitizer.care_records,
    icon: <Icon name="speedometer" sideMenu />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Reportes",
    to: urlsDigitizer.reports,
    icon: <Icon name="spreadsheet" sideMenu />,
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Opciones",
    icon: <Icon name="settings" sideMenu />,
    className: "d-md-block d-lg-none",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Notificaciones",
        className: "position-relative",
        icon: (
          <div>
            <Icon name="bell" size="lg" className="mr-2" />
            <small className="number-notifications number-notifications-menu-vertical">
              5
            </small>
          </div>
        ),
        onClick: onHandleShowSideNotifications,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Modo oscuro",
        icon: <Icon name="moon" size="lg" className="mr-2" />,
        onClick: onHandleActiveModedark,
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Cerrar sesión",
    className: "d-lg-none",
    icon: <Icon name="accountLogout" sideMenu />,
    onClick: () => logOut(),
  },
];

const logOut = () => {
  signOut(auth)
    .then(() => {
      sessionStorage.removeItem("isLogged");
      sessionStorage.setItem("signOutSuccessfully", true);
      window.location = "/";
    })
    .catch((error) => {
      // An error happened.
    });
};

export { navAdmin, navDigitizer };
