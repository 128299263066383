import React from "react";
import PropTypes from "prop-types";
import { deepObjectsMerge, getColor } from "@coreui/utils";
import { CChartPie } from "@coreui/react-chartjs";
import { BackgroundColorsDoughnut, colorsBadged } from "../../../commons/consts/colors";
import { useSelector } from "react-redux";

const ChartDoughnutSimple = (props) => {
  const theme = useSelector((state) => state.theme);
  const { pointHoverBackgroundColor, dataPoints, pointed, positionLegend, ...attributes } = props;

  const defaultDatasets = (() => {
    return [
      {
        data: dataPoints,
        backgroundColor: dataPoints[0] !== -1 ? getColor(BackgroundColorsDoughnut(dataPoints)) : colorsBadged(10),
      },
    ];
  })();

  const pointedOptions = (() => {
    return {};
  })();

  const straightOptions = (() => {
    return {};
  })();

  const defaultOptions = (() => {
    const options = pointed ? pointedOptions : straightOptions;
    return Object.assign({}, options, {
      maintainAspectRatio: false,
      legend: {
        display: !pointed,
        position: positionLegend,
        labels: {
          fontColor: theme === "light" ? "#727272" : "#FFF",
          boxWidth: 25,
          fontSize: 13,
        },
      },
      tooltips: {
        enabled: true,
      },
    });
  })();

  const computedDatasets = (() => {
    return deepObjectsMerge(defaultDatasets, attributes.datasets || {});
  })();

  const computedOptions = (() => {
    return deepObjectsMerge(defaultOptions, attributes.options || {});
  })();

  // render
  return <CChartPie {...attributes} datasets={computedDatasets} options={computedOptions} />;
};

ChartDoughnutSimple.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  dataPoints: PropTypes.array,
  pointed: PropTypes.bool,
  positionLegend: PropTypes.string,
};

ChartDoughnutSimple.defaultProps = {
  dataPoints: [-1, -1, -1, -1],
  labels: ["Opción 1", "Opción 2", "Opción 3", "Opción 4"],
  label: "Sales",
  positionLegend: "left",
};

export default ChartDoughnutSimple;
