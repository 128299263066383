import React from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";

const WebCam = ({ webCamRef, screenshotFormat }) => {
  return (
    <Webcam
      className="webcam-responsive"
      ref={(ref) => (webCamRef.current = ref)}
      screenshotFormat={screenshotFormat}
    />
  );
};

export default WebCam;

WebCam.propTypes = {
  screenshotFormat: PropTypes.string,
  ref: PropTypes.func,
};

WebCam.defaultProps = {
  screenshotFormat: "image/png",
  ref: () => {},
};
