import React from "react";
import { CButton } from "@coreui/react";
import Icon from "../Icon";
import Imagen from "../Imagen";

const Button = ({
  color,
  text,
  className,
  classNameIcon,
  onClick,
  variant,
  size,
  sizeIcon = size,
  type,
  disabled,
  icon,
  image,
  width,
}) => {
  return (
    <CButton
      size={size}
      color={color}
      className={`rounded-lg ${className}`}
      onClick={onClick}
      variant={variant}
      type={type}
      disabled={disabled}
    >
      {icon ? (
        <>
          <Icon name={icon} size={sizeIcon} className={classNameIcon} />{" "}
          <small className="font-bold">{text}</small>
        </>
      ) : image ? (
        <Imagen src={image} width={width} />
      ) : (
        text
      )}
    </CButton>
  );
};

export default Button;
