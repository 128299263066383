import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const DivTransition = ({ children, ...props }) => {
  const {
    id,
    keyframer,
    animate,
    initial,
    custom,
    variants,
    layout,
    exit,
    className,
    whileHover,
  } = props;
  return (
    <motion.div
      id={id}
      key={keyframer}
      className={className}
      initial={initial}
      animate={animate}
      custom={custom}
      variants={variants}
      layout={layout}
      exit={exit}
      whileHover={whileHover}
    >
      {children}
    </motion.div>
  );
};

DivTransition.propTypes = {
  id: PropTypes.string,
  keyframer: PropTypes.any,
  animate: PropTypes.any,
  initial: PropTypes.any,
  exit: PropTypes.any,
  variants: PropTypes.object,
  custom: PropTypes.number,
  layout: PropTypes.bool,
  whileHover: PropTypes.any,
};

DivTransition.defaultProps = {
  keyframer: "test",
  layout: false,
  animate: "visible",
  initial: "hidden",
  exit: "exit",
  hover: "hover",
  custom: 0,
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.5, duration: 1 } },
    exit: { opacity: 1, y: "100vh", transition: { duration: 2 } },
  },
};

export default DivTransition;
