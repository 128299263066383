import React from "react";
import { CContainer, CCol, CRow, CCard, CCardHeader, CCardBody, CCardFooter } from "@coreui/react";
import { TableCharts, ChartLineCard } from "../../molecules";
import { Badge, Spinner } from "../../atoms";
import { footerFontChartsText } from "../../../commons/static/tabs";
import { elementsTabReports } from "../../../commons/static/tabs";
import { useSelector } from "react-redux";

const TableChartsLayout = ({ tabName, data, spinner }) => {
  const theme = useSelector((state) => state.theme);
  const { atenttionEmotional, atenttionLegal, rangeAges } = data;
  return (
    <>
      {tabName === elementsTabReports[4].name && (
        <CContainer fluid>
          <div>
            <h2 className="mt-4 text-center p-2 font-semi-bold">Tablas del área emocional</h2>
            {spinner ? (
              <div>
                <Spinner width={230} height="auto" />
                <p className="h4 font-regular text-center">Espere un momento por favor...</p>
              </div>
            ) : (
              <CRow>
                <CCol sm="12" md="6">
                  <TableCharts title="CITAS ÁREA EMOCIONAL" data={atenttionEmotional.emotionalModules} />
                </CCol>
                <CCol sm="12" md="6">
                  <TableCharts title="ÁREAS DE AFECTACIÓN" data={atenttionEmotional.violenceAreas} />
                </CCol>
              </CRow>
            )}

            <h2 className="mt-4 text-center p-2 font-semi-bold">Tablas del área legal</h2>

            {spinner ? (
              <div>
                <Spinner width={230} height="auto" />
                <p className="h4 font-regular text-center">Espere un momento por favor...</p>
              </div>
            ) : (
              <>
                <CRow>
                  <CCol sm="12" md="6">
                    <TableCharts title="DELITOS" data={atenttionLegal.crimes} />
                  </CCol>
                  <CCol sm="12" md="6">
                    <TableCharts title="DERECHOS DE FAMILIA" data={atenttionLegal.familyRights} />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" md="6">
                    <TableCharts title="CITAS ÁREA LEGAL" data={atenttionLegal.legalModules} />
                  </CCol>
                  <CCol sm="12" md="6">
                    <TableCharts title="APOYO BRINDADO" data={atenttionLegal.support} />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" md="6">
                    <TableCharts title="VIOLENCIAS" data={atenttionLegal.violenceTypes} />
                  </CCol>
                </CRow>
              </>
            )}

            <CCard className="border-0">
              <CCardHeader className="font-weight-bold h4 bg-gradient-blue text-white m-0">Rango en edades</CCardHeader>
              <CCardBody className={`px-5 py-4 ${theme === "dark" ? "bg-dark" : ""}`}>
                <CRow>
                  <CCol>
                    <ChartLineCard
                      titleLitte="Gráfico"
                      title="EDADES DE LAS MUJERES"
                      dataPoints={rangeAges.dataPoints}
                      labels={rangeAges.labels}
                      label="EDADES"
                      nTotal={rangeAges.total}
                      footerText={footerFontChartsText}
                      loading={spinner}
                      min={0}
                    />
                  </CCol>
                </CRow>
                <CRow className="justify-content-center text-center">
                  {rangeAges.labels.map((item, index) => (
                    <CCol key={item} sm="6" md="4" lg="3" className="mb-3">
                      <div className="h5">{rangeAges.dataPoints[index]}</div>
                      <Badge color={rangeAges.colors[index]} className="w-100 p-2" label={item} icon={false} />
                    </CCol>
                  ))}
                </CRow>
              </CCardBody>
              <CCardFooter className={`text-center ${theme === "dark" ? "bg-dark text-white" : "bg-light text-dark"}`}>
                Esta tabla ilustra el rango de las edades de víctimas sobrevivientes a algún tipo de violencia.
              </CCardFooter>
            </CCard>
          </div>
        </CContainer>
      )}
    </>
  );
};

export default TableChartsLayout;
