import React from "react";
import { CProgress, CCol, CRow } from "@coreui/react";
import { Label } from "../../atoms";

const ProgressBar = ({ title, showValue, valueProgress, size = "sm", xs = "12", md = "8" }) => {
  return (
    <CRow style={{ height: 80 }} className="align-items-center justify-content-end">
      <CCol xs={xs} md={md}>
        <p className="h4 font-bold">{title}</p>
        <CProgress animated showValue={showValue} color="purple" value={valueProgress} className="mb-1 bg-secondary font-black" size={size} />
        <div className="d-flex flex-row justify-content-between">
          <Label text="Iniciado" className="font-semiBold h6" />
          <Label text="En proceso" className="font-semiBold h6" />
          <Label text="Finalizado" className="font-semiBold h6" />
        </div>
      </CCol>
    </CRow>
  );
};

export default ProgressBar;
