import { URL, buildRequest } from "../../config/config";
import { modifiedInformedConsent } from "../helpers/utils";

const registerGeneralTab = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerCareRecords",
  };
  try {
    const responseFetch = await fetch(URL + "registerGeneralTab", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerEmotionalTab = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerEmotionalTab",
  };
  try {
    const responseFetch = await fetch(URL + "registerEmotionalTab", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerFollow = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerFollow",
  };
  try {
    const responseFetch = await fetch(URL + "registerFollow", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerAccompaniment = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerAccompaniment",
  };
  try {
    const responseFetch = await fetch(URL + "registerAccompaniment", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerMultimediaAL = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerMultimediaAL",
    multimedias: [],
  };
  try {
    const responseFetch = await fetch(URL + "registerMultimediaAL", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerMultimediaAE = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerMultimediaAE",
    multimedias: [],
  };
  try {
    const responseFetch = await fetch(URL + "registerMultimediaAE", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerTestAppQuote = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerTestAppQuote",
    multimedias: [],
  };
  try {
    const responseFetch = await fetch(URL + "registerTestAppQuote", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerQuoteFollow = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerQuoteFollow",
    multimedias: [],
  };
  try {
    const responseFetch = await fetch(URL + "registerQuoteFollow", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

// Delete
const deleteFileInFirestorage = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en deleteFileInFirestorage",
  };
  try {
    const responseFetch = await fetch(URL + "deleteFileInFirestorage", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const registerResourcesUsed = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerResources",
  };
  try {
    const responseFetch = await fetch(URL + "registerResourcesUsed", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

// Get
const getAccompanimentsReasons = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getAccompanimentsReasons",
    accompaniments: [],
  };
  try {
    const responseFetch = await fetch(URL + "getAccompanimentsReasons", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

// Prueba de espiracion de token
const getAllCareRecordsList = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getAllCareRecordsList",
    careRecordsList: [],
  };
  try {
    const responseFetch = await fetch(URL + "getAllCareRecordsList", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const getInfoCareRecord = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getInfoCareRecord",
    data: [],
  };
  try {
    const responseFetch = await fetch(URL + "getInfoCareRecord", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const getAllInfoCareRecord = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getAllInfoCareRecord",
    data: [],
  };
  try {
    const responseFetch = await fetch(URL + "getAllInfoCareRecord", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const downloadModifiedPDF = async (issue, options) => {
  switch (issue) {
    case "informedConsent":
      return await modifiedInformedConsent(options);
    default:
      break;
  }
};

const setChangeStatusCase = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en setChangeStatusCase",
  };
  try {
    const responseFetch = await fetch(URL + "setChangeStatusCase", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

export {
  registerGeneralTab,
  registerFollow,
  registerEmotionalTab,
  getAllCareRecordsList,
  getAccompanimentsReasons,
  registerAccompaniment,
  registerMultimediaAL,
  registerMultimediaAE,
  deleteFileInFirestorage,
  registerTestAppQuote,
  registerQuoteFollow,
  registerResourcesUsed,
  getInfoCareRecord,
  getAllInfoCareRecord,
  downloadModifiedPDF,
  setChangeStatusCase,
};
