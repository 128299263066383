import React from "react";
import { CModal, CModalHeader, CModalFooter, CModalBody, CCard } from "@coreui/react";
import { Button } from "../../atoms";
import styled from "styled-components";

const ModalRegister = ({
  show,
  toggleModal,
  title,
  content,
  sizeModal = "",
  classModalHeader,
  onConfirm,
  disabledBtnConfirm,
  disabledOnModalClose,
  textButtonOnConfirm,
}) => {
  return (
    <CModal show={show} closeOnBackdrop={false} onClose={toggleModal} size={sizeModal} className="bg-gradient-modal">
      <StyledCard>
        <CModalHeader className={classModalHeader} closeButton={!disabledOnModalClose}>
          {title}
        </CModalHeader>
        <CModalBody>{content}</CModalBody>
        <CModalFooter>
          <Button color="danger" disabled={disabledOnModalClose} onClick={toggleModal} text="Cancelar" />
          <Button color="success" text={textButtonOnConfirm} disabled={disabledBtnConfirm} onClick={onConfirm} />{" "}
        </CModalFooter>
      </StyledCard>
    </CModal>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default ModalRegister;
