import React, { memo } from "react";
import { CRow, CCard } from "@coreui/react";
import { ChartDoughnutSimple, Spinner } from "../../atoms";
import styled from "styled-components";

const ChartDoughnutCard = ({ titleLitte, title, footerText, labels, dataPoints, loading, nTotal, positionLegend }) => {
  return (
    <StyledCard className="p-4">
      <CRow>
        <h3 className="text-purple">{titleLitte}</h3>
        <div className="line-vertical" />
        <h4 className="w-75 pt-1">{title}</h4>
      </CRow>
      <div className="chart-wrapper mt-3 text-right">
        {loading ? (
          <div>
            <Spinner width={230} height="auto" />
            <p className="h4 font-regular text-center">Espere un momento por favor...</p>
          </div>
        ) : (
          <>
            <h5>{nTotal && "TOTAL: " + nTotal}</h5>
            <ChartDoughnutSimple style={{ height: "250px" }} labels={labels} dataPoints={dataPoints} positionLegend={positionLegend} />
          </>
        )}
      </div>
      <small className="mt-3">{footerText}</small>
    </StyledCard>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default memo(ChartDoughnutCard);
