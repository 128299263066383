import React, { useEffect, useState } from "react";
import { CFormGroup, CLabel, CInputCheckbox } from "@coreui/react";

const CheckBoxes = ({ name, onChange, checked, list = [] }) => {
  const [options, setOptions] = useState([] || checked);

  useEffect(() => {
    setOptions(checked);
  }, []);

  return (
    <CFormGroup className="checkbox ml-3" name={name}>
      {list.map((item) => (
        <div key={item.value}>
          <CInputCheckbox
            id={`${name}_${item.label}`}
            value={item.value}
            dependency={item.dependency}
            onChange={onChange}
            checked={options.find((element) => (element.value === item.value ? true : false))}
          />
          <CLabel className="form-check-label" htmlFor={`${name}_${item.label}`} onChange={onChange}>
            {item.label}
          </CLabel>
        </div>
      ))}
    </CFormGroup>
  );
};

export default CheckBoxes;
