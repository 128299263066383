import React from "react";
import AsyncSelect from "react-select/async";

const SelectInput = ({ isLoading, placeholder = "Seleccione...", onChange, isDisabled, loadOptions, value, onInputChange }) => {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#000",
      padding: 5,
    }),
  };
  return (
    <AsyncSelect
      styles={customStyles}
      isClearable
      cacheOptions
      isLoading={isLoading}
      value={value}
      loadOptions={loadOptions}
      placeholder={placeholder}
      onChange={onChange}
      onInputChange={onInputChange}
      isDisabled={isDisabled}
      hideSelectedOptions
      menuShouldScrollIntoView
      noOptionsMessage={() => "No existe coincidencias."}
      loadingMessage={() => "Espere un momento por favor."}
    />
  );
};

export default SelectInput;
