import React from "react";
import { CContainer, CCol, CRow } from "@coreui/react";
import { DateTimePicker, ListGroup, ContainerSignature, CheckBoxesMultiple } from "..";
import { Label, Button, Dropdown, Input, TextArea, CheckBoxes, RadioButton, SelectInput, Imagen, Icon, Spinner } from "../../atoms";
import { useSelector } from "react-redux";
import { decodeFormatBase64 } from "../../../commons/consts/images";
import { validInputReExp } from "../../../commons/helpers/validations";
import { nameOrLastnameReg, numberIdentityReg, phoneNumberHomeReg, phoneNumberReg, ageReg } from "../../../commons/consts/regularexp";

const confirmation = [
  { value: 0, label: "NO" },
  { value: 1, label: "SÍ" },
];

const GeneralTab = ({
  paraments,
  controllers,
  onChangeInputs,
  deleteItem,
  loadOptionsSelectInput,
  onInputSelectChange,
  onEndSignature,
  onHandleClearSignature,
  onHandleSaveSignature,
  onHandleShowSignature,
}) => {
  const stateGlobal = useSelector((state) => ({
    violenceTypes: state.violenceTypes,
    attentionMode: state.attentionMode,
    affectationLevel: state.affectationLevel,
    displayName: state.currentUser.displayName,
    photoUser: state.currentUser.photoURL,
    victimRelationship: state.victimRelationship,
    ethnicities: state.ethnicities,
    maritalStatus: state.maritalStatus,
    educationLevel: state.educationLevel,
    dateCurrent: state.dateCurrent,
    sexualOrientations: state.sexualOrientations,
    disabilityTypes: state.disabilityTypes,
    aggressorTypes: state.aggressorTypes,
    familyRights: state.familyRights,
    crimes: state.crimes,
    support: state.support,
    caseReferred: state.caseReferred,
    virtualOptions: state.virtualOptions,
    theme: state.theme,
    users: state.users,
  }));
  const {
    names,
    lastnames,
    age,
    dateAreaLegal,
    hourAreaLegal,
    identity,
    tutor,
    ethnicities,
    maritalStatus,
    occupation,
    educationLevel,
    address,
    numDaughters,
    numSons,
    agesChildren,
    phoneNumber,
    phoneHouse,
    callEmergency,
    sexualOrientations,
    disability,
    disabilityType,
    aggressorNames,
    aggressorLastnames,
    aggressorIdentity,
    aggressorAge,
    aggressorEthnicity,
    aggressorEducationLevel,
    aggressorOccupation,
    aggressorAddress,
    aggressorPhoneNumber,
    aggressorPhoneHouse,
    aggressorPhoneWork,
    aggressorPlaceWork,
    aggressorType,
    aggressorNumber,
    aggressorViolenceTypes,
    familyRights,
    otherFamilyRights,
    crimes,
    otherCrime,
    description,
    support,
    usersAccess,
    otherSupport,
    caseReferred,
    caseFrom,
    otherReferredOgWomen,
    otherReferred,
    observationsLegal,
    recommendationsLegal,
    caseActions,
    virtualOptionsLegal,
    attentionModeLegal,
    digitalFootprint,
    photoUser,
    attentionBy,
  } = paraments;
  const { signatureState, spinner } = controllers;
  return (
    <CContainer fluid>
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <>
          <CRow className="justify-content-end">
            <p className="h5">
              La información obligatoria se visualiza con un <Label className="font-bold text-danger ml-1" isRequeried />
              <Icon name="handPointLeft" className="ml-1" size="1x" />
            </p>
          </CRow>
          <CRow>
            <CCol xs="12" lg="6">
              <h4 className="mt-3">1. Datos personales</h4>
              <Label text="1.1 Fecha y hora" isRequeried className="mt-3 h5" />
              <DateTimePicker
                disableTime2
                valueDate={dateAreaLegal}
                valueTime={hourAreaLegal}
                onChangeDate={(e) => onChangeInputs(e, "dateAreaLegal", "input")}
                onChangeTime={(e) => onChangeInputs(e, "hourAreaLegal", "input")}
                maxDate={stateGlobal.dateCurrent}
                disabled={true}
              />
              <Label text="1.2 Nombres" isRequeried className="mt-3 h5" />
              <Input
                noAutoComplete="true"
                type="text"
                value={names}
                classNameInput={validInputReExp(names, nameOrLastnameReg)}
                onChange={(e) => onChangeInputs(e, "names", "input")}
              />
              <Label text="1.3 Apellidos" isRequeried className="mt-3 h5" />
              <Input
                type="text"
                noAutoComplete="true"
                value={lastnames}
                classNameInput={validInputReExp(lastnames, nameOrLastnameReg)}
                onChange={(e) => onChangeInputs(e, "lastnames", "input")}
              />
              <Label text="1.4 Identidad" isRequeried className="mt-3 h5" />
              <Input
                type="text"
                classNameInput={`${validInputReExp(identity, numberIdentityReg)} w-50`}
                value={identity}
                onChange={(e) => onChangeInputs(e, "identity", "input")}
                maxLength={13}
              />
              <Label text="1.5 Edad" isRequeried className="mt-3 h5" />
              <Input
                type="number"
                classNameInput={`${validInputReExp(age, ageReg)} w-50`}
                value={age}
                onChange={(e) => onChangeInputs(e, "age", "number")}
                min={0}
                max={120}
              />
              {age <= 18 && (
                <div className={`${stateGlobal.theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} mt-3 rounded-lg px-4 pb-4`}>
                  <Label text="1.5.1 Nombres del tutor(a)" isRequeried className="mt-3 h5" />
                  <Input
                    type="text"
                    noAutoComplete="true"
                    value={tutor.names}
                    classNameInput={validInputReExp(tutor.names, nameOrLastnameReg)}
                    onChange={(e) => onChangeInputs(e, "names", "tutor")}
                  />
                  <Label text="1.5.2 Apellidos del tutor(a)" isRequeried className="mt-3 h5" />
                  <Input
                    type="text"
                    noAutoComplete="true"
                    value={tutor.lastnames}
                    classNameInput={validInputReExp(tutor.lastnames, nameOrLastnameReg)}
                    onChange={(e) => onChangeInputs(e, "lastnames", "tutor")}
                  />
                  <Label text="1.5.3 Identidad del tutor(a)" isRequeried className="mt-3 h5" />
                  <Input
                    type="text"
                    noAutoComplete="true"
                    value={tutor.identity}
                    classNameInput={validInputReExp(tutor.identity, numberIdentityReg)}
                    onChange={(e) => onChangeInputs(e, "identity", "tutor")}
                    maxLength={13}
                  />
                  <Label text="1.5.4 Teléfono celular de tutor(a)" isRequeried className="mt-3 h5" />
                  <Input
                    type="number"
                    noAutoComplete="true"
                    value={tutor.numberPhone}
                    onChange={(e) => onChangeInputs(e, "numberPhone", "tutor")}
                    classNameInput={validInputReExp(tutor.numberPhone, phoneNumberReg)}
                    maxLength={8}
                    min={0}
                  />
                  <Label text="1.5.5 Parentesco con tutor(a)" isRequeried className="mt-3 h5" />
                  <Dropdown
                    data={stateGlobal.victimRelationship}
                    className="w-75"
                    placeholder={tutor.victimRelationship.label}
                    color={tutor.victimRelationship.value !== -1 ? "purple" : "secondary"}
                    onClick={(e) => onChangeInputs(e, "victimRelationship", "dropdown-tutor")}
                  />
                </div>
              )}
              <Label text="1.6 Grupo étnico al que pertenece" className="mt-3 h5" isRequeried />
              <Dropdown
                data={stateGlobal.ethnicities}
                className="w-75"
                placeholder={ethnicities.label}
                color={ethnicities.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "ethnicities", "dropdown")}
              />
              <Label isRequeried text="1.7 Nivel educativo" className="mt-3 h5" />
              <Dropdown
                data={stateGlobal.educationLevel}
                className="w-75"
                placeholder={educationLevel.label}
                color={educationLevel.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "educationLevel", "dropdown")}
              />
              <Label text="1.8 Profesión u oficio (víctima)" className="mt-3 h5" isRequeried />
              <SelectInput
                isLoading={controllers.loadingSelectInput}
                className="w-75"
                value={occupation}
                onChange={(e) => onChangeInputs(e, "occupation", "select-input")}
                loadOptions={loadOptionsSelectInput}
                onInputChange={onInputSelectChange}
              />
              <Label isRequeried text="1.9 Dirección / Residencia" className="mt-3 h5" />
              <TextArea value={address} onChange={(e) => onChangeInputs(e, "address", "input")} maxLength={500} />
              <Label text="1.10 Estado civil" isRequeried className="mt-3 h5" />
              <Dropdown
                data={stateGlobal.maritalStatus}
                className="w-75"
                placeholder={maritalStatus.label}
                color={maritalStatus.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "maritalStatus", "dropdown")}
              />
              <Label text="1.11 Hijas e hijos" className="mt-3 h5" />
              <Input
                placeholder="Número de hijas"
                type="number"
                classNameInput="w-75"
                value={numDaughters}
                min={0}
                onChange={(e) => onChangeInputs(e, "numDaughters", "number")}
              />
              <Input
                placeholder="Número de hijos"
                type="number"
                classNameInput="w-75 my-3"
                value={numSons}
                min={0}
                onChange={(e) => onChangeInputs(e, "numSons", "number")}
              />
              <TextArea
                placeholder="Edades de los hijos"
                maxLength={500}
                className="mt-3"
                value={agesChildren}
                onChange={(e) => onChangeInputs(e, "agesChildren", "input")}
              />
              <Label text="1.12 Teléfonos" isRequeried className="mt-3 h5" />
              <Input
                placeholder="Número de celular"
                type="number"
                classNameInput={`${validInputReExp(phoneNumber, phoneNumberReg)} w-75`}
                maxLength={8}
                min={0}
                value={phoneNumber}
                onChange={(e) => onChangeInputs(e, "phoneNumber", "number")}
              />
              <Input
                placeholder="Teléfono de casa"
                type="number"
                classNameInput={`${validInputReExp(phoneHouse, phoneNumberHomeReg)} mt-3`}
                maxLength={8}
                value={phoneHouse}
                onChange={(e) => onChangeInputs(e, "phoneHouse", "number")}
                min={0}
              />
              <Label text="1.13 En caso de emergencia llamar a:" className="mt-3 h5" isRequeried />
              <div className={`${stateGlobal.theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded-lg p-3`}>
                <Input
                  placeholder="Nombre completo"
                  value={callEmergency.nameComplete}
                  onChange={(e) => onChangeInputs(e, "nameComplete", "call-emergency")}
                  classNameInput={validInputReExp(callEmergency.nameComplete, nameOrLastnameReg)}
                />
                <Input
                  placeholder="Teléfono celular"
                  classNameInput={`${validInputReExp(callEmergency.phoneNumber, phoneNumberReg)} mt-3`}
                  type="number"
                  min={0}
                  maxLength={8}
                  value={callEmergency.phoneNumber}
                  onChange={(e) => onChangeInputs(e, "phoneNumber", "call-emergency")}
                />
                <Input
                  placeholder="Teléfono de trabajo"
                  classNameInput={`${validInputReExp(callEmergency.phoneWork, phoneNumberHomeReg)} my-3`}
                  type="number"
                  maxLength={8}
                  min={0}
                  value={callEmergency.phoneWork}
                  onChange={(e) => onChangeInputs(e, "phoneWork", "call-emergency")}
                />
                <TextArea
                  placeholder="Dirección de trabajo"
                  className="mt-2"
                  maxLength={500}
                  value={callEmergency.address}
                  onChange={(e) => onChangeInputs(e, "address", "call-emergency")}
                />
              </div>
              <Label isRequeried text="1.14 Orientación sexual" className="mt-3 h5" />
              <Dropdown
                data={stateGlobal.sexualOrientations}
                className="w-75"
                placeholder={sexualOrientations.label}
                color={sexualOrientations.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "sexualOrientations", "dropdown")}
              />
              <Label isRequeried text="1.15 Discapacidad" className="mt-3 h5" />
              <RadioButton
                name="confirmation"
                classNameGroup="ml-4"
                options={confirmation}
                onChange={(e) => onChangeInputs(e, "disability", "radio-button")}
                optionSelected={disability}
              />
              {disability.value === "1" && (
                <>
                  <Label text="1.16 Tipo de discapacidad" className="mt-3 h5" />
                  <CheckBoxes
                    name="disabilityType"
                    classNameGroup="ml-4"
                    list={stateGlobal.disabilityTypes}
                    onChange={(e) => onChangeInputs(e, "disabilityType", "check-box")}
                    checked={disabilityType}
                  />
                </>
              )}
              <Label text="4. Descripción del problema" className="h5 mt-3" isRequeried />
              <TextArea maxLength={3000} value={description} onChange={(e) => onChangeInputs(e, "description", "input")} />
              <Label text="5. Observaciones" className="h5 mt-3" isRequeried />
              <TextArea maxLength={3000} value={observationsLegal} onChange={(e) => onChangeInputs(e, "observationsLegal", "input")} />
              <Label text="6. Acciones a realizar" className="h5 mt-3" isRequeried />
              <TextArea maxLength={3000} value={caseActions} onChange={(e) => onChangeInputs(e, "caseActions", "input")} />
              <Label text="7. Caso remitido por:" className="h5 mt-3" />
              <TextArea maxLength={1000} value={caseFrom} onChange={(e) => onChangeInputs(e, "caseFrom", "input")} />
              <Label text="8. Caso remitido a:" className="h5" isRequeried />
              <CheckBoxes
                name="support"
                classNameGroup="ml-4"
                list={stateGlobal.caseReferred}
                onChange={(e) => onChangeInputs(e, "caseReferred", "check-box")}
                checked={caseReferred}
              />
              <Input
                placeholder="Organización de mujeres u otras."
                classNameInput="my-2"
                value={otherReferredOgWomen}
                onChange={(e) => onChangeInputs(e, "otherReferredOgWomen", "input")}
              />
              <Input
                placeholder="Instituciones estatales o dependencias."
                value={otherReferred}
                onChange={(e) => onChangeInputs(e, "otherReferred", "input")}
              />
              <Label text="9. Recomendaciones" className="h5 mt-3" isRequeried />
              <TextArea
                maxLength={3000}
                value={recommendationsLegal}
                onChange={(e) => onChangeInputs(e, "recommendationsLegal", "input")}
              />
            </CCol>
            <CCol xs="12" lg="6" className={`${stateGlobal.theme === "dark" ? "bg-dark text-white" : "bg-light text-dark"} py-3`}>
              <h4>2. Datos del denunciado</h4>
              <Label text="2.1 Nombres" isRequeried className="mt-3 h5" />
              <Input
                type="text"
                noAutoComplete="true"
                value={aggressorNames}
                classNameInput={validInputReExp(aggressorNames, nameOrLastnameReg)}
                onChange={(e) => onChangeInputs(e, "aggressorNames", "input")}
              />
              <Label text="2.2 Apellidos" isRequeried className="mt-3 h5" />
              <Input
                type="text"
                noAutoComplete="true"
                value={aggressorLastnames}
                classNameInput={validInputReExp(aggressorLastnames, nameOrLastnameReg)}
                onChange={(e) => onChangeInputs(e, "aggressorLastnames", "input")}
              />
              <Label text="2.3 Identidad" className="mt-3 h5" />
              <Input
                type="text"
                classNameInput={`${validInputReExp(aggressorIdentity, numberIdentityReg)} w-50`}
                value={aggressorIdentity}
                onChange={(e) => onChangeInputs(e, "aggressorIdentity", "input")}
                maxLength={13}
              />
              <Label text="2.4 Edad" className="mt-3 h5" isRequeried />
              <Input
                type="number"
                classNameInput={`${validInputReExp(aggressorAge, ageReg)} w-50`}
                value={aggressorAge}
                onChange={(e) => onChangeInputs(e, "aggressorAge", "number")}
                min={0}
                max={120}
              />
              <Label text="2.5 Grupo etnico al que pertenece" className="mt-3 h5" isRequeried />
              <Dropdown
                data={stateGlobal.ethnicities}
                className="w-75"
                placeholder={aggressorEthnicity.label}
                color={aggressorEthnicity.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "aggressorEthnicity", "dropdown")}
              />
              <Label isRequeried text="2.6 Nivel educativo" className="mt-3 h5" />
              <Dropdown
                data={stateGlobal.educationLevel}
                className="w-75"
                placeholder={aggressorEducationLevel.label}
                color={aggressorEducationLevel.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "aggressorEducationLevel", "dropdown")}
              />
              <Label text="2.7 Profesión u oficio (agresor)" className="mt-3 h5" isRequeried />
              <SelectInput
                isLoading={controllers.loadingSelectInput}
                className="w-75"
                value={aggressorOccupation}
                onChange={(e) => onChangeInputs(e, "aggressorOccupation", "select-input")}
                loadOptions={loadOptionsSelectInput}
                onInputChange={onInputSelectChange}
              />
              <Label isRequeried text="2.8 Dirección / Residencia" className="mt-3 h5" />
              <TextArea value={aggressorAddress} maxLength={500} onChange={(e) => onChangeInputs(e, "aggressorAddress", "input")} />
              <Label text="2.9 Teléfonos" className="mt-3 h5" />
              <Input
                type="number"
                noAutoComplete="true"
                placeholder="Teléfono celular"
                classNameInput={`${validInputReExp(aggressorPhoneNumber, phoneNumberReg)} w-75`}
                value={aggressorPhoneNumber}
                onChange={(e) => onChangeInputs(e, "aggressorPhoneNumber", "number")}
                maxLength={8}
                min={0}
              />
              <Input
                noAutoComplete="true"
                placeholder="Teléfono casa"
                type="number"
                classNameInput={`${validInputReExp(aggressorPhoneHouse, phoneNumberHomeReg)} w-75 my-3`}
                maxLength={8}
                min={0}
                value={aggressorPhoneHouse}
                onChange={(e) => onChangeInputs(e, "aggressorPhoneHouse", "number")}
              />
              <Input
                noAutoComplete="true"
                placeholder="Teléfono trabajo"
                type="number"
                maxLength={8}
                min={0}
                value={aggressorPhoneWork}
                onChange={(e) => onChangeInputs(e, "aggressorPhoneWork", "number")}
                classNameInput={validInputReExp(aggressorPhoneWork, phoneNumberHomeReg)}
              />
              <Label text="2.10 Lugar y Dirección de trabajo" className="mt-3 h5" />
              <TextArea value={aggressorPlaceWork} onChange={(e) => onChangeInputs(e, "aggressorPlaceWork", "input")} maxLength={500} />
              <Label text="2.11 Número de agresores" className="mt-3 h5" />
              <Input
                classNameInput="w-75"
                type="number"
                value={aggressorNumber}
                onChange={(e) => onChangeInputs(e, "aggressorNumber", "input")}
                max={50}
                min={1}
              />
              <Label text="2.12 Parentesco(s) con denunciado" isRequeried className="mt-3 h5" />
              <CheckBoxes
                name="aggressorType"
                classNameGroup="ml-4"
                list={stateGlobal.aggressorTypes}
                checked={aggressorType}
                onChange={(e) => onChangeInputs(e, "aggressorType", "check-box")}
              />
              <h4 className="mt-3 h5">3. Delitos o faltas denunciadas.</h4>
              <CheckBoxesMultiple
                title="3.1 Tipos de violencias"
                subTitle="Violencias localizadas:"
                data={stateGlobal.violenceTypes}
                controller={aggressorViolenceTypes}
                onChange={onChangeInputs}
                field="aggressorViolenceTypes"
                classNameTitle="h5"
              />
              <ListGroup
                isRequeried
                title="3.2 Derechos de familia"
                list={stateGlobal.familyRights}
                placeholder={familyRights.selected}
                itemsSelected={familyRights.items}
                onClick={(e) => onChangeInputs(e, "familyRights", "list-group")}
                itemDelete={(e) => deleteItem(e, "familyRights")}
              />
              <Input
                placeholder="Otros o no aplica"
                value={otherFamilyRights}
                onChange={(e) => onChangeInputs(e, "otherFamilyRights", "input")}
              />
              <ListGroup
                isRequeried
                title="3.3 Delitos y/o faltas identificadas"
                list={stateGlobal.crimes}
                placeholder={crimes.selected}
                itemsSelected={crimes.items}
                onClick={(e) => onChangeInputs(e, "crimes", "list-group")}
                itemDelete={(e) => deleteItem(e, "crimes")}
              />
              <Input placeholder="Otros o no aplica" value={otherCrime} onChange={(e) => onChangeInputs(e, "otherCrime", "input")} />
              <Label text="10. Apoyo brindado" className="h5 mt-3" isRequeried />
              <CheckBoxes
                name="support"
                classNameGroup="ml-4"
                list={stateGlobal.support}
                onChange={(e) => onChangeInputs(e, "support", "check-box")}
                checked={support}
              />
              <Input placeholder="Otros" value={otherSupport} onChange={(e) => onChangeInputs(e, "otherSupport", "input")} />
              <Label text="11. Acceso a colaboradoras" className="h5 mt-3" isRequeried />
              <CheckBoxes
                name="usersAccess"
                classNameGroup="ml-4"
                list={stateGlobal.users}
                onChange={(e) => onChangeInputs(e, "usersAccess", "check-box")}
                checked={usersAccess}
              />
              <Label text="12. Modalidad de atención" className="h5 mt-3" isRequeried />
              <Dropdown
                data={stateGlobal.attentionMode}
                color={attentionModeLegal.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "attentionModeLegal", "dropdown")}
                placeholder={attentionModeLegal.label}
              />
              {attentionModeLegal.value === "R925ema5ejFwt1y4HICn" && (
                <div className={`${stateGlobal.theme === "dark" ? "bg-info text-white" : "bg-light text-dark"} rounded p-3 my-3`}>
                  <Label isRequeried text="12.1 - Elegir el o los tipos de modalidades virtuales" />
                  <CheckBoxes
                    name="virtualOptionsLegal"
                    list={stateGlobal.virtualOptions}
                    checked={virtualOptionsLegal}
                    onChange={(e) => onChangeInputs(e, "virtualOptionsLegal", "check-box")}
                  />
                </div>
              )}
              <Label text="13. Firma digital" className="h5 mt-4" isRequeried />
              <Button
                text="Digite una firma"
                className="w-100 bg-purple text-white"
                onClick={() => onHandleShowSignature(!signatureState.show)}
              />
              <div className="text-center">
                {!signatureState.show && digitalFootprint && (
                  <Imagen src={decodeFormatBase64 + digitalFootprint} width="50%" className="bg-light p-3 rounded-lg mt-3" />
                )}
              </div>

              {signatureState.show && (
                <ContainerSignature
                  canvasProps={{
                    height: 280,
                    width: 320,
                    className: stateGlobal.theme === "light" ? "bg-gray" : "bg-white",
                  }}
                  signatureState={signatureState}
                  onClickBtnSave={onHandleSaveSignature}
                  onEnd={onEndSignature}
                  onClickBtnClear={onHandleClearSignature}
                />
              )}
              <Label text="Atendida por:" className="h5 mt-4" />
              <div className="d-flex flex-row align-items-center">
                <Imagen src={photoUser || stateGlobal.photoUser} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{attentionBy || stateGlobal.displayName.username}</p>
              </div>
            </CCol>
          </CRow>
        </>
      )}
    </CContainer>
  );
};

export default GeneralTab;
