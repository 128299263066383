import React from "react";
import { useSelector } from "react-redux";
import { Container } from "../../templates";
import { SideMenu, Header, Notifications } from "../../organims";
import { ThemeProvider } from "styled-components";

const lightTheme = {
  pageBackground: "#e5e9ed",
  cardBackground: "#ebedef",
  titleColor: "#000000",
  tagLineColor: "black",
};
const darkTheme = {
  pageBackground: "#282c36",
  cardBackground: "#474a55",
  titleColor: "#ffffff",
  tagLineColor: "white",
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

const Digitizer = () => {
  const modeTheme = useSelector((state) => state.theme);
  return (
    <ThemeProvider theme={themes[modeTheme]}>
      <div className="c-app c-default-layout">
        <SideMenu />
        <div className="c-wrapper">
          <Header />
          <div className="c-body">
            <Container />
          </div>
          {/* <Footer /> */}
          <Notifications />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Digitizer;
