import React from "react";
import { CContainer } from "@coreui/react";

const DigitizerContent = () => {
  return (
    <CContainer>
      <h1>Contenido de las digitadoras</h1>
    </CContainer>
  );
};

export default DigitizerContent;
