import React from "react";
import { CContainer, CCol, CRow } from "@coreui/react";
import { DateTimePicker, CheckBoxesMultiple, ListGroup, ProgressBar } from "..";
import { Input, Label, CheckBoxes, Spinner, RadioButton, TextArea, Dropdown, Imagen } from "../../atoms";
import { useSelector } from "react-redux";

const confirmation = [
  { value: 0, label: "NO" },
  { value: 1, label: "SI" },
  { value: 3, label: "NO APLICA" },
];

const QuoteFollowForm = ({ paraments, onChangeInputs, deleteItem, controllers }) => {
  const stateGlobal = useSelector((state) => ({
    photoUser: state.currentUser.photoURL,
    displayName: state.currentUser.displayName,
    affectationLevel: state.affectationLevel,
    violenceTypes: state.violenceTypes,
    therapeuticTechniques: state.therapeuticTechniques,
    virtualOptions: state.virtualOptions,
    dateCurrent: state.dateCurrent,
    attentionMode: state.attentionMode,
    generalAsssessment: state.generalAsssessment,
  }));
  const {
    names,
    lastnames,
    age,
    dateQuoteFollow,
    hourQuoteFollow,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    feedBack,
    therapeuticCompliance,
    developmentAttention,
    violencesApproachesQuoteFollow,
    affectationLevelQuoteFollow,
    generalAsssessmentQuoteFollow,
    therapeuticTechniquesQuoteFollow,
    attentionModeQuoteFollow,
    virtualOptionQuoteFollow,
    progressQuoteFollow,
    photoUser,
    attentionBy,
  } = paraments;
  const { spinner } = controllers;
  return (
    <CContainer fluid className="py-4">
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <>
          <CRow>
            <CCol xs="12" lg="6">
              <Label text="1. Fecha y hora" className="h5" isRequeried />
              <DateTimePicker
                disabled
                disableTime2
                valueDate={dateQuoteFollow}
                valueTime={hourQuoteFollow}
                onChangeDate={(e) => onChangeInputs(e, "dateQuoteFollow", "input")}
                onChangeTime={(e) => onChangeInputs(e, "hourQuoteFollow", "input")}
                maxDate={stateGlobal.dateCurrent}
              />
              <Label text="2. Nombres" className="h5 mt-3" />
              <Input type="text" value={names} disabled={true} />
              <Label text="3. Apellidos" className="h5 mt-3" />
              <Input type="text" value={lastnames} disabled={true} />
              <Label text="4. Edad" className="h5 mt-3" />
              <Input type="number" value={age} disabled={true} />
              <Label text="5. Retroalimentación" className="h5 mt-3" isRequeried />
              <TextArea maxLength={3000} value={feedBack} onChange={(e) => onChangeInputs(e, "feedBack", "input")} />
              <Label text="6. Cumplimiento de las asignaciones terapétuticas" className="h5 mt-3" isRequeried />
              <RadioButton
                name="therapeuticCompliance"
                options={confirmation}
                optionSelected={therapeuticCompliance}
                classNameGroup="ml-4"
                onChange={(e) => onChangeInputs(e, "therapeuticCompliance", "radio-button")}
              />

              <CheckBoxesMultiple
                title="7. Tipos de violencias abordadas"
                subTitle="Violencias abordadas:"
                data={stateGlobal.violenceTypes}
                controller={violencesApproachesQuoteFollow}
                onChange={onChangeInputs}
                field="violencesApproachesQuoteFollow"
                classNameTitle="h5"
              />
            </CCol>
            <CCol xs="12" lg="6">
              <Label text="8. Desarrollo de la atención" className="h5" isRequeried />
              <TextArea
                maxLength={3000}
                value={developmentAttention}
                onChange={(e) => onChangeInputs(e, "developmentAttention", "input")}
              />

              <Label text="9. Progreso" className="mt-3 h5" />
              <Input
                type="number"
                value={progressQuoteFollow}
                onChange={(e) => onChangeInputs(e, "progressQuoteFollow", "number")}
                placeholder="Nivel de progreso"
                classNameInput="w-50"
                min={0}
                max={100}
              />
              <ProgressBar xs="12" md="12" valueProgress={progressQuoteFollow} />

              <Label text="10. Agenda próxima cita" className="h5" isRequeried />
              <DateTimePicker
                valueDate={nextQuoteDate}
                valueTime={nextQuoteHourStart}
                valueTime2={nextQuoteHourEnd}
                onChangeDate={(e) => onChangeInputs(e, "nextQuoteDate", "input")}
                onChangeTime={(e) => onChangeInputs(e, "nextQuoteHourStart", "input")}
                onChangeTime2={(e) => onChangeInputs(e, "nextQuoteHourEnd", "input")}
                minDate={stateGlobal.dateCurrent}
              />
              <Label text="11. Nivel de afectación por violencias" className="mt-3 h5" isRequeried />
              <RadioButton
                name="level_afectation"
                classNameGroup="ml-4"
                options={stateGlobal.affectationLevel}
                onChange={(e) => onChangeInputs(e, "affectationLevelQuoteFollow", "radio-button")}
                optionSelected={affectationLevelQuoteFollow}
              />
              <Label text="12.	Valoración general del caso" className="mt-3 h5" isRequeried />
              <RadioButton
                name="generalAsssessmentQuoteFollow"
                classNameGroup="ml-4"
                options={stateGlobal.generalAsssessment}
                optionSelected={generalAsssessmentQuoteFollow}
                onChange={(e) => onChangeInputs(e, "generalAsssessmentQuoteFollow", "radio-button")}
              />
              <ListGroup
                isRequeried
                title="13. Técnicas terapéuticas utilizadas"
                list={stateGlobal.therapeuticTechniques}
                placeholder={therapeuticTechniquesQuoteFollow.selected}
                itemsSelected={therapeuticTechniquesQuoteFollow.items}
                onClick={(e) => onChangeInputs(e, "therapeuticTechniquesQuoteFollow", "list-group")}
                itemDelete={(e) => deleteItem(e, "therapeuticTechniquesQuoteFollow")}
              />

              <Label text="14. Modalidad" className="mt-3 h5" isRequeried />
              <Dropdown
                className="w-75 mb-3"
                data={stateGlobal.attentionMode}
                color={attentionModeQuoteFollow.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "attentionModeQuoteFollow", "dropdown")}
                placeholder={attentionModeQuoteFollow.label}
              />
              {attentionModeQuoteFollow.value === "R925ema5ejFwt1y4HICn" && (
                <div className="bg-light rounded p-3 my-3 text-dark">
                  <Label isRequeried text="14.1 - Elegir el o los tipos de modalidades virtuales" />
                  <CheckBoxes
                    name="virtualOptionQuoteFollow"
                    list={stateGlobal.virtualOptions}
                    checked={virtualOptionQuoteFollow}
                    onChange={(e) => onChangeInputs(e, "virtualOptionQuoteFollow", "check-box")}
                  />
                </div>
              )}
              <Label text="Atendida por:" className="h5 mt-3" />
              <div className="d-flex flex-row align-items-center">
                <Imagen src={photoUser || stateGlobal.photoUser} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{attentionBy || stateGlobal.displayName.username}</p>
              </div>
            </CCol>
          </CRow>
        </>
      )}
    </CContainer>
  );
};

export default QuoteFollowForm;
