import { auth, URL, buildRequest } from "../../config/config";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { errorsAuth, errorsResetPassword } from "../helpers/errors";

const getParamentsInitials = async (idToken) => {
  let response = {
    success: false,
    title: "",
    message: "",
    code: null,
    data: {},
  };
  try {
    const responseFetch = await fetch(URL + "setParamentsInitials", buildRequest({ idToken }));
    const responseDate = await fetch(URL + "getDateTime", buildRequest({ idToken }));
    const json = await responseFetch.json();
    const jsonDate = await responseDate.json();
    // Se retorna en responseFetch roles y charges.
    // Se retorna en responseDate date y timeZone.
    response = {
      ...json,
      ...jsonDate,
      success: json.success && jsonDate.success,
    };
  } catch (error) {
    response.success = false;
    response.message = error.message;
    response.code = error.code;
  } finally {
    return response;
  }
};

const loginUser = async (email, password) => {
  let response = { success: false, message: "" };
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    if (user) {
      response.success = true;
    }
  } catch (error) {
    response.success = false;
    response.message = errorsAuth(error.code);
    response.code = error.code;
  } finally {
    return response;
  }
};

const passwordResetEmail = async (email) => {
  let response = { success: false, message: "" };
  try {
    const resFirebase = await sendPasswordResetEmail(auth, email);
    if (!resFirebase) {
      response.success = true;
      response.message = "Hemos enviado un enlace para restablecer tu contraseña, favor verifique su correo.";
    }
  } catch (error) {
    response.message = errorsResetPassword(error.code);
  } finally {
    return response;
  }
};

const registerNewUser = async (data) => {
  try {
    const response = await fetch(URL + "registerUser", buildRequest(data));
    const json = await response.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      code: error.code,
      message: error.message,
    };
  }
};

const updateUser = async (data) => {
  try {
    const response = await fetch(URL + "updateUser", buildRequest(data));
    const json = await response.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      code: error.code,
      message: error.message,
    };
  }
};

const getAllUsers = async (data) => {
  try {
    const response = await fetch(URL + "getAllUsers", buildRequest(data));
    const json = await response.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      code: error.code,
      message: error.message,
    };
  }
};

const getUserData = async (data) => {
  try {
    const response = await fetch(URL + "getUserData", buildRequest(data));
    const json = await response.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      code: error.code,
      message: error.message,
    };
  }
};

const disabledUser = async (data) => {
  try {
    const response = await fetch(URL + "disabledUser", buildRequest(data));
    const json = await response.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      code: error.code,
      message: error.message,
    };
  }
};

const getInfoSurvey = async (data) => {
  try {
    const response = await fetch(URL + "getInfoSurvey", buildRequest(data));
    const json = await response.json();
    return json;
  } catch (error) {
    return {
      success: false,
      title: "Error",
      code: error.code,
      message: error.message,
    };
  }
};

export {
  loginUser,
  passwordResetEmail,
  registerNewUser,
  getParamentsInitials,
  getAllUsers,
  disabledUser,
  updateUser,
  getUserData,
  getInfoSurvey,
};
