import React from "react";
import { CInput, CInputGroup, CInputGroupPrepend, CInputGroupText } from "@coreui/react";
import { Icon } from "../../atoms";
import { useSelector } from "react-redux";

const Input = ({
  id,
  icon,
  placeholder,
  type,
  onChange,
  className,
  classNameInput,
  value,
  min,
  max,
  name,
  disabled,
  maxLength,
  noAutoComplete,
}) => {
  const theme = useSelector((state) => state.theme);
  return (
    <>
      {icon ? (
        <CInputGroup className={className}>
          <CInputGroupPrepend>
            <CInputGroupText className="bg-light">
              <Icon name={icon} size="xl" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            id={id}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            min={min}
            max={max}
            name={name}
            disabled={disabled}
            className={`text-dark ${classNameInput}`}
            maxLength={maxLength}
            autoComplete={noAutoComplete}
          />
        </CInputGroup>
      ) : (
        <CInput
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          min={min}
          max={max}
          name={name}
          disabled={disabled}
          className={`${theme === "dark" ? "text-white bg-info" : "text-dark bg-light"} ${classNameInput}`}
          maxLength={maxLength}
          autoComplete={noAutoComplete}
        />
      )}
    </>
  );
};

export default Input;
