const errorsAuth = (code) => {
  let message = "";
  switch (code) {
    case "auth/invalid-email":
      message = "Correo inválido.";
      break;
    case "auth/wrong-password":
      message = "Contraseña incorrecta.";
      break;
    case "auth/user-signed-out":
      message =
        "Señal de internet muy débil o conexión inestable, favor intentar más tarde.";
      break;
    case "auth/user-disabled":
      message =
        "Usuaria deshabilitada, favor contactar con una de las administradoras para más información.";
      break;
    case "auth/user-not-found":
      message = "Usuaria no encontrada en el sistema.";
      break;
    case "auth/user-token-expired":
      message = "Token de acceso a expirado.";
      break;
    case "auth/too-many-requests":
      message =
        "Ha realizado varios intentos de sesión, intentelo de nuevo en unos minutos.";
      break;
    default:
      message = "Error desconocido al ingresar al sistema.";
      break;
  }
  return message;
};

const errorsRegister = (code) => {
  let message = "";
  switch (code) {
    case "auth/weak-password":
      message =
        "Contraseña muy débil, la contraseña debe superar mínimo 6 carácteres.";
      break;
    default:
      message = "Error desconocido al regustrar nueva usuaria.";
      break;
  }
  return message;
};

const errorsResetPassword = (code) => {
  let message = "";
  switch (code) {
    case "auth/user-not-found":
      message = "El correo digitado no existe, favor verificar.";
      break;
    case "auth/invalid-email":
      message = "El correo es inválido.";
      break;
    default:
      message = "Error desconocido al restablecer la contraseña.";
      break;
  }
  return message;
};

const errorsUpLoadStorage = (code) => {
  let messageError = "";
  switch (code) {
    case "storage/unauthorized":
      messageError = "No esta autorizado a subir archivos.";
      break;
    case "storage/canceled":
      messageError = "Archivo cancelado.";
      break;
    case "storage/unknown":
      messageError = "Error desconocido al subir archivo.";
      break;
    case "storage/object-not-found":
      messageError = "No existe ningún objeto en la referencia deseada.";
      break;
    case "storage/bucket-not-found":
      messageError = "No se configuró ningún bucket para Cloud Storage.";
      break;
    case "storage/project-not-found":
      messageError = "No se configuró ningún proyecto para Cloud Storage.";
      break;
    case "storage/quota-exceeded":
      messageError =
        "Se superó la cuota del bucket de Cloud Storage. Si estás en el nivel sin costo, deberás actualizar a un plan pagado. Si estás en un plan pagado, comunícate con el personal de asistencia de Firebase.";
      break;
    case "storage/unauthenticated":
      messageError =
        "El usuario no se autenticó. Vuelve a intentarlo después de realizar la autenticación.";
      break;
    case "storage/retry-limit-exceeded":
      messageError =
        "Se superó el límite de tiempo máximo permitido para una operación (de carga, descarga, eliminación, etc.). Vuelve a subirlo.";
      break;
    case "storage/invalid-checksum":
      messageError =
        "El archivo del cliente no coincide con la suma de verificación del archivo que recibió el servidor. Vuelve a subirlo.";
      break;
    case "storage/invalid-url":
      messageError =
        "Se proporcionó una URL no válida a refFromURL(). Debes usar el formato gs://bucket/object o https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=&ltTOKEN>";
      break;
    case "storage/invalid-argument":
      messageError =
        "El argumento que se pase a put() debe ser un arreglo de tipo `File`, `Blob` o `UInt8`. El argumento que se pase a putString() debe ser una string sin procesar `Base64` o `Base64URL`.";
      break;
    case "storage/no-default-bucket":
      messageError =
        "No se estableció ningún bucket en la propiedad storageBucket de tu configuración.";
      break;
    case "storage/cannot-slice-blob":
      messageError =
        "Ocurre generalmente cuando hubo un cambio en el archivo local (se borró, se volvió a guardar, etc.) Intenta volver a subirlo después de verificar que el archivo no haya cambiado.";
      break;
    case "storage/server-file-wrong-size":
      messageError =
        "El archivo del cliente no coincide con el tamaño del archivo que recibió el servidor. Vuelve a subirlo.";
      break;
    default:
      break;
  }
  return messageError;
};

export { errorsAuth, errorsRegister, errorsResetPassword, errorsUpLoadStorage };
