import React, { useEffect, useState } from "react";
import { CContainer } from "@coreui/react";
import { Calendar, FollowForm, AccompanimentForm, TestAppQuoteForm, QuoteFollowForm } from "..";
import { Spinner, Label, Button } from "../../atoms";

const CalendarForm = ({ state, onChangeCalendar, paraments, onChangeInputs, deleteItem, handleEventClick, handleBlack }) => {
  const [initialView, setInitialView] = useState("");
  useEffect(() => {
    setInitialView("dayGridMonth");
  }, []);

  const { spinner, issueQuote } = state;
  return (
    <CContainer fluid>
      {issueQuote && (
        <div className="d-flex flex-row">
          <Button text="Regresar" icon="chervonLeft" color="purple" onClick={handleBlack} />
          <Label text={issueQuote} className="h4 font-regular ml-3 pt-2" />
        </div>
      )}
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : issueQuote === "AL/Seguimiento" ? (
        <FollowForm paraments={paraments} controllers={state} onChangeInputs={onChangeInputs} deleteItem={deleteItem} />
      ) : issueQuote === "AL/Acompañamiento" ? (
        <AccompanimentForm paraments={paraments} controllers={state} onChangeInputs={onChangeInputs} />
      ) : issueQuote === "AE/Citas de Seguimiento" ? (
        <QuoteFollowForm paraments={paraments} controllers={state} onChangeInputs={onChangeInputs} deleteItem={deleteItem} />
      ) : issueQuote === "AE/Cita de Aplicación de pruebas" ? (
        <TestAppQuoteForm paraments={paraments} controllers={state} onChangeInputs={onChangeInputs} />
      ) : (
        <Calendar state={state} initialView={initialView} onChangeCalendar={onChangeCalendar} handleEventClick={handleEventClick} />
      )}
    </CContainer>
  );
};

export default CalendarForm;
