import React from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
import { Label, Imagen } from "../../atoms";
import imgFormList from "../../../assets/form-list.svg";
import PropTypes from "prop-types";

const AlertNoApply = ({ text, image }) => {
  return (
    <CContainer fluid>
      <CRow>
        <CCol className="align-self-center">
          <Imagen src={image} width="100%" height={400} />
          <Label text={text} className="h4 font-regular text-center w-100" />
        </CCol>
      </CRow>
    </CContainer>
  );
};

AlertNoApply.defaultProps = {
  text: "Se sugiere primero registrar la ficha general, para continuar este apartado.",
  image: imgFormList,
};

AlertNoApply.propTypes = {
  text: PropTypes.string,
  image: PropTypes.any,
};

export default AlertNoApply;
