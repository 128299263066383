import React, { useState } from "react";
import { CBadge, CDataTable, CCollapse, CCardBody, CButtonGroup, CRow, CCol, CEmbed } from "@coreui/react";
import { InputFile, Button, Imagen, Icon, Spinner } from "../../atoms";
import { fieldsMultimedia } from "../../../commons/static/tables";
import ReactPlayer from "react-player";

const ContainerFiles = ({
  uid,
  title,
  filePath,
  data,
  dataArg,
  field,
  loadingButtons,
  onChange,
  accept,
  deleteFile,
  donwloadFile,
  storageName,
}) => {
  const [details, setDetails] = useState([]);

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const getBadge = (status) => {
    switch (status) {
      case "audio":
        return "success";
      case "video":
        return "blue";
      case "image":
        return "warning";
      default:
        return "danger";
    }
  };

  return (
    <div className="bg-secondary text-dark my-3 p-3 rounded">
      <p className="h4 font-regular">{title}</p>
      <InputFile uid={uid} field={field} placeholder={filePath && filePath.name} onChange={onChange} accept={accept} dataArg={dataArg} />
      {loadingButtons && (
        <div className="w-100 text-center py-2">
          <Spinner width={80} height={80} />
          <p className="font-semi-bold h6">Por favor espere un momento...</p>
        </div>
      )}
      <div className="bg-light text-dark rounded-lg">
        <CDataTable
          items={data}
          fields={fieldsMultimedia}
          hover
          sorter
          scopedSlots={{
            type: (item) => (
              <td>
                <CBadge color={getBadge(item.type)}>{item.type}</CBadge>
              </td>
            ),
            show_details: (item, index) => {
              return (
                <td className="py-2">
                  <Button
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      toggleDetails(index);
                    }}
                    text={details.includes(index) ? "Ocultar" : "Mostrar"}
                  />
                </td>
              );
            },
            details: (item, index) => {
              return (
                <CCollapse show={details.includes(index)} className="bg-white text-dark">
                  <CCardBody>
                    <CRow className="align-self-center">
                      <CCol xs="4">
                        <p className="text-muted">Subido o actualizado: {item.registered}</p>
                        <CButtonGroup className="text-center">
                          <div>
                            <Button
                              type="a"
                              icon="download"
                              color="success"
                              variant="outline"
                              className="mr-1"
                              size="lg"
                              onClick={() => donwloadFile(item.name, field)}
                            />
                            <p className="small mr-1 text-center font-regular text-success">Descargar</p>
                          </div>
                          <div>
                            <Button
                              icon="trash"
                              color="danger"
                              variant="outline"
                              size="lg"
                              className="ml-1"
                              onClick={() => deleteFile(index, item.name, dataArg, storageName)}
                            />
                            <p className="small ml-1 text-center font-regular text-danger">Eliminar</p>
                          </div>
                        </CButtonGroup>
                      </CCol>
                      <CCol xs="8">
                        {item.type === "image" && <Imagen src={item.url} fluidGrow />}
                        {item.type === "application" && item.name.substring(item.name.length - 3, item.name.length) === "pdf" && (
                          <CEmbed ratio="16by9" style={{ height: 450 }}>
                            <iframe src={item.url} title={item.name}></iframe>
                          </CEmbed>
                        )}
                        {item.type === "video" ||
                          (item.type === "audio" && (
                            <ReactPlayer controls url={item.url} width="100%" height={item.type === "audio" ? 50 : 260} />
                          ))}
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCollapse>
              );
            },
          }}
        />
      </div>
      <p className="mt-3 font-regular">
        <Icon name="handPointRight" color="#bb2865" size="lg" /> Asegurese de dar click en{" "}
        <small className="h6 font-bold text-success">GUARDAR</small> antes de salir de esta ventana, así los cambios se almacenarán
        correctamente.
      </p>
    </div>
  );
};
export default ContainerFiles;
