import React from "react";
import { TableRegister, ModalRegister, VariableRegisterForm } from "..";
const TabCategories = ({
  showModal,
  toggleModal,
  fields,
  state,
  onChangedEnabledDisabled,
  onPaginationChange,
  onActivePageChange,
  onChangeInputs,
  paraments,
  handleOnConfirm,
  onReadySearches,
}) => {
  return (
    <>
      <ModalRegister
        show={showModal}
        toggleModal={toggleModal}
        content={
          <VariableRegisterForm
            loading={state.spinner}
            dependency={state.dependency}
            dependencyItems={state.dependencyItems}
            paraments={paraments}
            categoryName={state.tabCurrent}
            onChangeInputs={onChangeInputs}
          />
        }
        textButtonOnConfirm={state.id ? "Actualizar" : "Guardar"}
        onConfirm={handleOnConfirm}
      />
      <TableRegister
        switchEnable
        elements={state}
        fields={fields}
        onChangedEnabledDisabled={onChangedEnabledDisabled}
        toggleModal={toggleModal}
        onPaginationChange={onPaginationChange}
        onActivePageChange={onActivePageChange}
        onTableFilterChange={onChangeInputs}
        onReadySearches={onReadySearches}
      />
    </>
  );
};

export default TabCategories;
