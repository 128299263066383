import React from "react";
import { CContainer, CCol, CRow } from "@coreui/react";
import { DateTimePicker, CheckBoxesMultiple, ProgressBar } from "..";
import { useSelector } from "react-redux";
import { Input, Label, Spinner, CheckBoxes, RadioButton, TextArea, Imagen } from "../../atoms";

const confirmation = [
  { value: 0, label: "NO" },
  { value: 1, label: "SÍ" },
];

const AccompanimentForm = ({ paraments, onChangeInputs, controllers }) => {
  const stateGlobal = useSelector((state) => ({
    actionsPublic: state.actionsPublic,
    dateCurrent: state.dateCurrent,
    accompaniments: state.accompaniments,
    photoUser: state.currentUser.photoURL,
    displayName: state.currentUser.displayName,
  }));
  const {
    names,
    lastnames,
    dateAccompaniment,
    hourAccompaniment,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    observationsAccompaniment,
    otherActionPublic,
    actionsPublic,
    justiceOperators,
    incidentsJusticeOperators,
    accompanimentsReasons,
    othersAccompanimentsReasons,
    progressAccompaniment,
    photoUser,
    attentionBy,
  } = paraments;
  const { spinner } = controllers;
  return (
    <CContainer fluid className="py-4">
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <>
          <CRow>
            <CCol xs="12" lg="6">
              <Label text="1. Fecha y hora" className="h5" isRequeried />
              <DateTimePicker
                disabled
                disableTime2
                valueDate={dateAccompaniment}
                valueTime={hourAccompaniment}
                onChangeDate={(e) => onChangeInputs(e, "dateAccompaniment", "input")}
                onChangeTime={(e) => onChangeInputs(e, "hourAccompaniment", "input")}
                maxDate={stateGlobal.dateCurrent}
              />
              <Label text="2. Nombres" className="h5 mt-3" />
              <Input type="text" value={names} disabled={true} />
              <Label text="3. Apellidos" className="h5 mt-3" />
              <Input type="text" value={lastnames} disabled={true} />
              <Label text="4. Acciones a realizar" className="h5 mt-3" isRequeried />
              <CheckBoxes
                name="actionsPublic"
                classNameGroup="ml-4"
                list={stateGlobal.actionsPublic}
                onChange={(e) => onChangeInputs(e, "actionsPublic", "check-box")}
                checked={actionsPublic}
              />
              <Input placeholder="Otros" value={otherActionPublic} onChange={(e) => onChangeInputs(e, "otherActionPublic", "input")} />

              <Label text="Atendida por:" className="h5 mt-3" />
              <div className="d-flex flex-row align-items-center">
                <Imagen src={photoUser || stateGlobal.photoUser} width={80} height={80} className="rounded-circle" />
                <p className="h5 font-italic ml-2">{attentionBy || stateGlobal.displayName.username}</p>
              </div>
            </CCol>
            <CCol xs="12" lg="6">
              <Label text="5. Incidencias con operadores de justicia en el caso" className="h5 mt-3" isRequeried />
              <RadioButton
                name="justiceOperators"
                options={confirmation}
                optionSelected={justiceOperators}
                classNameGroup="ml-4"
                onChange={(e) => onChangeInputs(e, "justiceOperators", "radio-button")}
              />
              {justiceOperators.value === "1" && (
                <TextArea
                  placeholder="Describa el por qué..."
                  maxLength={3000}
                  value={incidentsJusticeOperators}
                  onChange={(e) => onChangeInputs(e, "incidentsJusticeOperators", "input")}
                />
              )}
              <Label text="6. Observaciones" className="h5 mt-3" isRequeried />
              <TextArea
                maxLength={3000}
                value={observationsAccompaniment}
                onChange={(e) => onChangeInputs(e, "observationsAccompaniment", "input")}
              />
              <Label text="7. Progreso" className="mt-3 h5" isRequeried />
              <Input
                type="number"
                value={progressAccompaniment}
                onChange={(e) => onChangeInputs(e, "progressAccompaniment", "number")}
                placeholder="Nivel de progreso"
                classNameInput="w-50"
                min={0}
                max={100}
              />
              <ProgressBar xs="12" md="12" valueProgress={progressAccompaniment} />
              <Label text="8. Próxima cita" className="mt-3 h5" isRequeried />
              <DateTimePicker
                valueDate={nextQuoteDate}
                valueTime={nextQuoteHourStart}
                valueTime2={nextQuoteHourEnd}
                onChangeDate={(e) => onChangeInputs(e, "nextQuoteDate", "input")}
                onChangeTime={(e) => onChangeInputs(e, "nextQuoteHourStart", "input")}
                onChangeTime2={(e) => onChangeInputs(e, "nextQuoteHourEnd", "input")}
                minDate={stateGlobal.dateCurrent}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CheckBoxesMultiple
                isRequeried
                title="9. Lugares y motivos"
                subTitle="Motivos agregados:"
                data={stateGlobal.accompaniments}
                controller={accompanimentsReasons}
                onChange={onChangeInputs}
                field="accompanimentsReasons"
                classNameTitle="h5"
              />
              <TextArea
                placeholder="Otros lugares y motivos"
                maxLength={1500}
                value={othersAccompanimentsReasons}
                onChange={(e) => onChangeInputs(e, "othersAccompanimentsReasons", "input")}
              />
            </CCol>
          </CRow>
        </>
      )}
    </CContainer>
  );
};

export default AccompanimentForm;
