const addFontWeight = (element, array) => {
  let id;
  for (var i = 0; i < array.length; i++) {
    id = document.getElementById(array[i].id);
    if (array[i].name === element) {
      id.style.fontWeight = "bold";
    } else {
      id.style.fontWeight = "normal";
    }
  }
};

const addFontWeightReports = (element, array) => {
  let id;
  for (var i = 0; i < array.length; i++) {
    id = document.getElementById(array[i].id);
    if (array[i].name === element) {
      id.style.fontWeight = "bold";
    } else {
      id.style.fontWeight = "normal";
    }
  }
};

export { addFontWeight, addFontWeightReports };
