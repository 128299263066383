import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSidebar, CSidebarMinimizer } from "@coreui/react";
import { setShowSideBar } from "../../../actionsCreators/Global";
import { SideBarNav, SideBarBrand } from "../../molecules";
import { Icon } from "../../atoms";
// sidebar nav config
import { navAdmin, navDigitizer } from "../../../commons/static/Nav/nav";

const SideMenu = () => {
  const dispatch = useDispatch();
  const [nav, setNav] = useState(navDigitizer);
  const show = useSelector((state) => state.showSideBar);
  const theme = useSelector((state) => state.theme);
  const rolId = useSelector((state) => state.currentUser.displayName.id_rol.value);

  const onShowChange = (val) => {
    dispatch(setShowSideBar(val));
  };

  const onChangeModeDark = useCallback(() => {
    let tempNav = rolId === "KVr8DMQv2hb0r8lhrShP" ? navAdmin : navDigitizer;
    if (tempNav[6]) {
      tempNav[6]._children[1].name = theme === "light" ? "Modo oscuro" : "Modo claro";
      tempNav[6]._children[1].icon =
        theme === "light" ? <Icon name="moon" size="lg" className="mr-2" /> : <Icon name="sun" size="lg" className="mr-2" />;
      setNav(tempNav);
    }
  }, [rolId, theme]);

  useEffect(() => {
    onChangeModeDark();
  }, [onChangeModeDark]);

  return (
    <CSidebar show={show} onShowChange={onShowChange} className="bg-primary">
      <SideBarBrand />
      <SideBarNav navigation={nav} />
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default SideMenu;
