import React, { useEffect, memo, useCallback } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setShowSideBar, setShowNotifications, setTheme } from "../../../actionsCreators/Global";
import { CHeader, CToggler, CRow } from "@coreui/react";
import { HeaderNav } from "../../molecules";
import { Imagen } from "../../atoms";
import { setToSideNavBar } from "../../../config/user";
import { signOut } from "firebase/auth";
import { auth } from "../../../config/config";
import styled from "styled-components";

const StyledHeader = styled(CHeader)`
  color: ${(props) => props.theme.titleColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.showSideBar);
  const showNotifications = useSelector((state) => state.showSideNotifications);
  const theme = useSelector((state) => state.theme);
  const { photoURL, displayName } = useSelector((state) => state.currentUser);

  const toggleSidebar = () => {
    const val = sidebarShow ? false : true;
    dispatch(setShowSideBar(val));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow) ? true : "responsive";
    dispatch(setShowSideBar(val));
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        sessionStorage.removeItem("isLogged");
        sessionStorage.setItem("signOutSuccessfully", true);
        history.push("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const ativeModeDark = useCallback(() => {
    if (theme === "light") {
      dispatch(setTheme("dark"));
      localStorage.setItem("theme", "dark");
    } else {
      dispatch(setTheme("light"));
      localStorage.setItem("theme", "light");
    }
    setToSideNavBar(theme, "set-mode-dark-active");
  }, [dispatch, theme]);

  const openNotifications = useCallback(() => {
    dispatch(setShowNotifications(!showNotifications));
  }, [dispatch, showNotifications]);

  useEffect(() => {
    setToSideNavBar(openNotifications, "function-show-notifications");
    setToSideNavBar(ativeModeDark, "function-active-mode-dark");
  }, [openNotifications, ativeModeDark]);

  return (
    <StyledHeader withSubheader className="align-items-center py-2">
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />

      <CToggler inHeader className="mx-2 d-md-down-none h-100" onClick={toggleSidebar} />

      <CRow className="justify-content-center align-items-center">
        <Imagen src={photoURL} className="c-sidebar-brand-full mr-2 ml-3 rounded-circle" width={70} height={70} />
        <h4 className="pt-2">
          <b>{displayName.username}</b>
          <br />
          <small>
            <i className="w-25">Bienvenida</i>
          </small>
        </h4>
      </CRow>
      <HeaderNav onClicklogout={logout} onClickShowNotifications={openNotifications} onClickModeDark={ativeModeDark} />
    </StyledHeader>
  );
};

export default memo(Header);
