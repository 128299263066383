const variantsNotifications = {
  hidden: { opacity: 0, x: "100vw", transition: { duration: 1 } },
  visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
};

const variantsAdminEvaluations = {
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.3, duration: 0.5 },
  }),
  hidden: { opacity: 0, y: -20 },
};

const variantItemNotification = {
  hidden: { opacity: 0, y: -20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: 0.3 * i, duration: 0.3 },
  }),
  hover: {
    scale: [1, 0.9, 1],
    transition: { duration: 0.2 },
  },
};

export { variantsNotifications, variantsAdminEvaluations, variantItemNotification };
