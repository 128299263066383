import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { CTabs, CNav, CNavItem, CNavLink, CTabContent, CCard, CCardBody, CCardTitle, CCol, CRow, CSpinner } from "@coreui/react";
import { Button, Toast } from "../../atoms";
import { TabPanes, ModalResetToken } from "../../molecules";
// Static
import { addFontWeight } from "../../../commons/helpers/addStyles";
import { fields, fieldsWidthDependency } from "../../../commons/static/tables";
// Helpers
import { validationsVariablesForm, verifyTokenExpired } from "../../../commons/helpers/validations";
// Services
import {
  getElementTabs,
  getListVariables,
  updateVariable,
  registerVariable,
  getListDropdownVariable,
} from "../../../commons/services/variables";
import styled from "styled-components";

const AdminLegal = () => {
  let timeOut = useRef(null);
  const displayName = useSelector((state) => state.currentUser.displayName);
  const idToken = useSelector((state) => state.currentUser.idToken);
  const stateGlobal = useSelector((state) => ({
    theme: state.theme,
    filterCases: state.filterCases,
  }));
  const { theme, filterCases } = stateGlobal;
  const [modal, setModal] = useState({
    show: false,
    token: false,
    spinner: false,
  });

  const [state, setState] = useState({
    id: "",
    data: [],
    elementTabs: [],
    tabCurrent: "",
    tabId: "tab-1",
    paginationTotal: 0,
    itemIndex: -1,
    dependency: "",
    filters: filterCases,
    dependencyItems: [],
    spinnerEnabled: false,
    spinner: true,
    spinnerTabs: true,
  });

  const [toast, setToast] = useState({
    show: false,
    title: "",
    className: "",
    textContent: "",
  });

  const [paraments, setParaments] = useState({
    name: "",
    description: "",
    dependency: { value: -1, label: "Seleccione" },
    enabled: true,
  });

  const onChangeInputs = (event, field, arg) => {
    const { target } = event;
    switch (arg) {
      case "input":
      case "text-area":
        setParaments((prevState) => ({
          ...prevState,
          [field]: target.value,
        }));
        break;
      case "tableFilterValue":
        if (field === "name") {
          setState((prevState) => ({
            ...prevState,
            filters: { ...state.filters, tableFilterValue: { ...state.filters.tableFilterValue, [field]: event } },
          }));
        } else if (field === "fields-clear") {
          setState((prevState) => ({
            ...prevState,
            spinner: true,
            filters: { ...state.filters, tableFilterValue: { name: "", startDate: "", endDate: "" } },
          }));
          onHandleGetListVariables(state.tabId, state.dependency, state.filters.limit, 1, {
            name: "",
            startDate: "",
            endDate: "",
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            filters: {
              ...state.filters,
              tableFilterValue: {
                ...state.filters.tableFilterValue,
                [field]: target.value,
              },
            },
          }));
        }
        break;
      default:
        setParaments((prevState) => ({
          ...prevState,
          [field]: {
            value: target.getAttribute("value"),
            label: target.text,
          },
        }));
        break;
    }
  };

  const onActiveTabChange = (element) => {
    const { elementTabs, filters } = state;
    const { tableFilterValue, limit } = filters;
    addFontWeight(element, elementTabs);
    const tab = state.elementTabs.filter((tab) => tab.name === element);
    setState((prevState) => ({
      ...prevState,
      tabCurrent: element,
      tabId: tab[0].id,
      spinner: true,
      dependency: tab[0].dependency,
    }));
    onHandleGetListVariables(tab[0].id, tab[0].dependency, limit, 1, tableFilterValue);
  };

  const checkFields = () => {
    // options para indicar acciones a realizar en la validacion.
    const options = { dependet: false };
    const verifyFields = validationsVariablesForm(paraments, options);

    if (verifyFields.valid) {
      setState((prevState) => ({ ...prevState, spinner: true }));
      if (state.id) {
        onHandleUpdateVariable();
      } else {
        onHandleRegisterVariable();
      }
    } else {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "Campos incorrectos",
        textContent: verifyFields.message,
        className: "bg-danger text-white",
      }));
      hiddenToast();
    }
  };

  const onHandleRegisterVariable = async () => {
    const { tabId, dependency, filters } = state;
    const { pagination, tableFilterValue, limit } = filters;
    try {
      const response = await registerVariable({
        idToken,
        idRol: displayName.id_rol.value,
        idTab: state.tabId,
        paraments,
        area: "area_legal",
      });
      if (response.success) {
        handdleOpenModal();
        await onHandleGetListVariables(tabId, dependency, limit, pagination, tableFilterValue);
        setToast(
          (prevState) => ({
            ...prevState,
            show: true,
            title: response.title,
            textContent: response.message,
            className: "bg-success text-white",
          }),
          clearParaments()
        );
      } else {
        if (verifyTokenExpired(response.code)) {
          setModal((prevState) => ({ ...prevState, show: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title: response.title,
            className: "text-white bg-danger",
            textContent: response.message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: error.title,
        textContent: error.message,
        className: "bg-success text-white",
      }));
    } finally {
      hiddenToast();
    }
  };

  const onHandleUpdateVariable = async () => {
    const { tabId, dependency, filters } = state;
    const { pagination, tableFilterValue, limit } = filters;
    try {
      const request = {
        id: state.id,
        idRol: displayName.id_rol.value,
        area: "area_legal",
        idTab: state.tabId,
        paramentsNews: paraments,
        idToken,
      };
      const { success, title, message, code } = await updateVariable(request);
      if (success) {
        handdleOpenModal();
        await onHandleGetListVariables(tabId, dependency, limit, pagination, tableFilterValue);
      } else if (verifyTokenExpired(code)) {
        setModal((prevState) => ({ ...prevState, show: false, token: true }));
      }
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title,
        textContent: message,
        className: success ? "bg-success text-white" : "bg-danger text-white",
      }));
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: error.title,
        textContent: error.message,
        className: "bg-danger text-white",
      }));
    } finally {
      hiddenToast();
    }
  };

  const onChangedEnabledDisabled = async (item, index) => {
    const { id, enabled, name } = item;
    let dataTemp = state.data;
    setState((prevState) => ({
      ...prevState,
      itemIndex: index,
      spinnerEnabled: true,
    }));
    try {
      const { success, message, title, code } = await updateVariable({
        idToken,
        id,
        area: "area_legal",
        idTab: state.tabId,
        enabled: !enabled,
        name,
        idRol: displayName.id_rol.value,
      });
      if (success) {
        dataTemp.splice(index, 1, { ...item, enabled: !enabled });
        setState((prevState) => ({
          ...prevState,
          data: dataTemp,
          dataFilter: dataTemp,
          itemIndex: -1,
          spinnerEnabled: false,
        }));
      } else if (verifyTokenExpired(code)) {
        setModal((prevState) => ({ ...prevState, show: false, token: true }));
      }
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title,
        textContent: message,
        className: success ? "bg-success text-white" : "bg-danger text-white",
      }));
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title: "Error",
        textContent: error.message,
        className: "bg-danger text-white",
      }));
    } finally {
      hiddenToast();
    }
  };

  const onHandleGetListVariables = useCallback(
    async (tabId, dependencyId, limit, pagination, filterValues) => {
      try {
        const { id_rol } = displayName;
        const { success, vars, title, message, paginationTotal, code } = await getListVariables({
          idToken,
          idRol: id_rol.value,
          area: "area_legal",
          idTab: tabId,
          pagination,
          limit,
          filterValues,
        });
        if (success) {
          if (dependencyId) {
            const { success, code, list, message } = await getListDropdownVariable({
              idTab: dependencyId,
              idRol: displayName.id_rol.value,
              area: "area_legal",
              idToken,
            });
            if (success || code === "id-tab-null") {
              setState((prevState) => ({
                ...prevState,
                data: vars,
                dependencyItems: list,
                paginationTotal,
                filters: { ...state.filters, pagination, limit, tableFilterValue: filterValues },
              }));
            } else {
              if (verifyTokenExpired(code)) {
                setModal((prevState) => ({ ...prevState, show: false, token: true }));
              } else {
                setToast((prevState) => ({
                  ...prevState,
                  show: true,
                  title,
                  className: "text-white bg-danger",
                  textContent: message,
                }));
              }
            }
          } else {
            setState((prevState) => ({
              ...prevState,
              data: vars,
              paginationTotal,
              filters: { ...state.filters, pagination, tableFilterValue: filterValues, limit },
            }));
          }
        } else {
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, show: false, token: true }));
          } else {
            setToast(
              (prevState) => ({
                ...prevState,
                show: true,
                title,
                className: "text-white bg-danger",
                textContent: message,
              }),
              setState((prevState) => ({
                ...prevState,
                data: [],
              }))
            );
          }
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "ERROR",
          className: "text-white bg-danger",
          textContent: error.message,
        }));
      } finally {
        hiddenToast();
        setState((prevState) => ({ ...prevState, spinner: false }));
      }
    },
    [displayName, idToken]
  );

  const onHandleGetElementTabs = useCallback(async () => {
    const { id_rol } = displayName;
    const { filters } = state;
    const { tableFilterValue, limit } = filters;
    try {
      const { success, tabs, title, message, code } = await getElementTabs({
        idToken,
        idRol: id_rol.value,
        area: "area_legal",
      });
      if (success) {
        setState(
          (prevState) => ({
            ...prevState,
            elementTabs: tabs,
            tabCurrent: tabs[0].name,
            tabId: tabs[0].id,
            dependency: tabs[0].dependency,
            spinnerTabs: false,
          }),
          await onHandleGetListVariables(tabs[0].id, tabs[0].dependency, limit, 1, tableFilterValue)
        );
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, show: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            textContent: message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: true,
        title: "ERROR",
        className: "text-white bg-danger",
        textContent: error.message,
      }));
    }
  }, [displayName, idToken]);

  const hiddenToast = () => {
    timeOut = setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false }));
    }, 6500);
  };

  const clearParaments = () => {
    setParaments(
      (prevState) => ({
        ...prevState,
        name: "",
        description: "",
        enabled: true,
        dependency: { value: -1, label: "Seleccione" },
      }),
      setState((prevState) => ({ ...prevState, itemIndex: -1, id: "" }))
    );
  };

  const handdleOpenModal = (item, index) => {
    if (item !== undefined) {
      if (item.id) {
        const { name, description, enabled, dependency } = item;
        setParaments(
          (prevState) => ({
            ...prevState,
            name,
            description,
            enabled,
            dependency,
          }),
          setState((prevState) => ({
            ...prevState,
            itemIndex: index,
            id: item.id,
          }))
        );
      } else {
        clearParaments();
      }
    }
    setModal((prevState) => ({ ...prevState, show: !modal.show }));
  };

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const onPaginationChange = (limit) => {
    const { tabId, dependency, filters } = state;
    const { tableFilterValue } = filters;
    setState((prevState) => ({ ...prevState, spinner: true }), onHandleGetListVariables(tabId, dependency, limit, 1, tableFilterValue));
  };

  const onActivePageChange = (page) => {
    const { tabId, dependency, filters } = state;
    const { tableFilterValue, limit } = filters;
    setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));
    onHandleGetListVariables(tabId, dependency, limit, page, tableFilterValue);
  };

  const onReadySearches = () => {
    const { tabId, dependency, filters } = state;
    const { tableFilterValue, limit } = filters;
    setState((prevState) => ({ ...prevState, spinner: true }));
    onHandleGetListVariables(tabId, dependency, limit, 1, tableFilterValue);
  };

  const willUnMount = useCallback(() => {
    clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    if (idToken) {
      onHandleGetElementTabs();
    }
    return willUnMount;
  }, [idToken, onHandleGetElementTabs, willUnMount]);

  return (
    <>
      <StyledCard>
        <CTabs activeTab={state.tabCurrent} onActiveTabChange={onActiveTabChange}>
          <CRow className="p-4">
            <CCol sm="6">
              <CCardTitle className="font-bold h2">Gestión de variables - Área Legal</CCardTitle>
            </CCol>
            <CCol sm="6" className="text-right">
              <Button
                text="CREAR NUEVO REGISTRO"
                //className="mr-2"
                color="success"
                onClick={handdleOpenModal}
              />
            </CCol>
          </CRow>

          <CNav variant="tabs" className="mb-3 px-4">
            {state.spinnerTabs ? (
              <CRow className="w-100 justify-content-center">
                <CSpinner color={theme === "light" ? "purple" : "light"} style={{ width: "4rem", height: "4rem" }} variant="grow" />
              </CRow>
            ) : (
              state.elementTabs.map((tab, index) => (
                <CNavItem key={tab.id}>
                  <CNavLink
                    data-tab={tab.name}
                    disabled={tab.name === state.tabCurrent ? true : false}
                    id={tab.id}
                    className={`mx-1 my-2 rounded ${
                      theme === "light" ? "text-dark bg-gray" : state.tabCurrent === tab.name ? "text-dark bg-white" : "text-white bg-dark"
                    }`}
                    style={{ fontWeight: index === 0 ? "bold" : "normal" }}
                  >
                    {tab.name}
                  </CNavLink>
                </CNavItem>
              ))
            )}
          </CNav>
          <CCardBody>
            <CTabContent>
              <TabPanes
                state={state}
                paraments={paraments}
                tabName={state.tabCurrent}
                fields={state.dependency ? fieldsWidthDependency("index") : fields("index")}
                showModal={modal.show}
                toggleModal={handdleOpenModal}
                onChangedEnabledDisabled={onChangedEnabledDisabled}
                onChangeInputs={onChangeInputs}
                handleOnConfirm={checkFields}
                onPaginationChange={onPaginationChange}
                onActivePageChange={onActivePageChange}
                onReadySearches={onReadySearches}
              />
            </CTabContent>
          </CCardBody>
        </CTabs>
      </StyledCard>
      <Toast show={toast.show} title={toast.title} className={toast.className} textContent={toast.textContent} autohide={6000} />
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
    </>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default AdminLegal;
