import React from "react";
import PropTypes from "prop-types";
import { getColor, deepObjectsMerge } from "@coreui/utils";
import { CChartBar } from "@coreui/react-chartjs";
import { useSelector } from "react-redux";

const ChartBarSimple = (props) => {
  const theme = useSelector((state) => state.theme);
  const { backgroundColor, pointHoverBackgroundColor, dataPoints, label, pointed, nTotal, min, ...attributes } = props;

  const defaultDatasets = (() => {
    return [
      {
        data: dataPoints,
        label: label,
        backgroundColor: getColor(backgroundColor),
        pointHoverBackgroundColor: getColor(pointHoverBackgroundColor),
        hoverBackgroundColor: "rgb(4,210,252, 0.6)",
        barPercentage: 0.4,
        categoryPercentage: 1,
        borderWidth: 1,
      },
    ];
  })();

  // Opciones dataPoints = null.
  const pointedOptions = (() => {
    return {
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              fontSize: 14,
              fontColor: theme === "light" ? "dark" : "white",
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            display: pointed,
            ticks: {
              fontSize: 14,
              showLabelBackdrop: true,
              fontColor: theme === "light" ? "dark" : "white",
              min: min,
              max: Math.max(...dataPoints) + Math.ceil(Math.max(...dataPoints) * 0.2),
            },
            gridLines: {
              color: theme === "light" ? "rgb(235,237,239)" : "rgb(71,74,85)",
            },
          },
        ],
      },
    };
  })();

  const defaultOptions = (() => {
    return Object.assign({}, pointedOptions, {
      maintainAspectRatio: false,
      legend: {
        display: !pointed,
        position: "bottom",
        labels: {
          fontSize: 14,
          fontColor: theme === "light" ? "black" : "white",
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.yLabel;
            const percentaje = ((value / nTotal) * 100).toFixed(1);
            const tag = nTotal ? `${percentaje} %, casos = ${value}` : `casos = ${value}`;
            return tag;
          },
          title: (tooltipItem, data) => {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
      animation: {
        duration: 1,
        onProgress: (animation) => {
          const chartInstance = animation.chart;
          const datasets = animation.chart.data.datasets;
          const ctx = animation.chart.ctx;

          ctx.textAlign = "center";
          ctx.fillStyle = theme === "light" ? "rgba(0, 0, 0, 1)" : "rgba(255, 255, 255, 1)";
          ctx.textBaseline = "bottom";

          datasets.forEach((dataset, i) => {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              var data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - 3);
            });
          });
        },
      },
    });
  })();

  const computedDatasets = (() => {
    return deepObjectsMerge(defaultDatasets, attributes.datasets || {});
  })();

  const computedOptions = (() => {
    return deepObjectsMerge(defaultOptions, attributes.options || {});
  })();

  // render
  return <CChartBar {...attributes} datasets={computedDatasets} options={computedOptions} />;
};

ChartBarSimple.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  //
  backgroundColor: PropTypes.string,
  pointHoverBackgroundColor: PropTypes.string,
  dataPoints: PropTypes.array,
  label: PropTypes.string,
  pointed: PropTypes.bool,
};

ChartBarSimple.defaultProps = {
  backgroundColor: "rgba(0,0,0,.2)",
  dataPoints: [10, 22, 34, 46, 58, 70, 46, 23, 45, 78, 34, 12],
  label: "Sales",
  min: 0,
};

export default ChartBarSimple;
