import React, { Component, createRef, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Admin, Login, Digitizer, NotMatch, Survey } from "./components/pages";
import { Spinner, Toast } from "./components/atoms";
import { urlsAdmin, urlsDigitizer } from "./commons/consts/urls";
import PrivateRouter from "./PrivateRouter";
import { auth } from "./config/config";
import { onAuthStateChanged } from "firebase/auth";
import { connect } from "react-redux";
import { getParamentsInitials } from "./commons/services/users";
import { setCurrentUser, setDateCurrent, setTheme, setFilterCases, setLoading, setVarsInitials } from "./actionsCreators/Global";
import store from "./store";
import "./scss/style.scss";

let modeTheme = "light";

class App extends Component {
  constructor(props) {
    super(props);
    this.timeOut = createRef();
    this.state = {
      toast: {
        show: false,
        title: "",
        message: "",
        className: "",
      },
    };
  }

  loading = () => (
    <div className="pt-3 text-center">
      <Spinner width="4rem" height="4rem" />
    </div>
  );

  getVariablesGlobales = async (idToken) => {
    try {
      const { success, title, message, data, date } = await getParamentsInitials(idToken);
      if (success) {
        this.props.onHandleSetDateCurrent(date);
        this.props.onHandleSetVarsInitials(data);
        this.props.onHandleSetLoading(false);
      } else {
        this.setState({
          ...this.state,
          toast: {
            title: title,
            show: true,
            message: message,
            className: "text-white bg-danger",
          },
        });
      }
    } catch (error) {
      this.setState({
        ...this.state,
        toast: {
          title: "ERROR",
          show: true,
          message: error.message,
          className: "text-white bg-danger",
        },
      });
    } finally {
      this.hiddenToast();
    }
  };

  hiddenToast = () => {
    this.timeOut = setTimeout(() => {
      this.setState({ ...this.state, toast: { show: false } });
    }, 5500);
  };

  componentDidMount() {
    // Initial
    this.props.onHandleSetLoading(true);
    const stateGlobal = store.getState();
    auth.languageCode = "es";
    modeTheme = localStorage.getItem("theme") || stateGlobal.theme;
    this.props.onHandleSetTheme(modeTheme);
    this.props.onHandleSetFilterCases(stateGlobal.filterCases);

    onAuthStateChanged(auth, (user) => {
      //console.log(user);
      if (user) {
        let displayName = JSON.parse(user.displayName);
        this.getVariablesGlobales(user.accessToken);
        this.props.onHandleSetCurrentUser({
          displayName,
          email: user.email,
          uid: user.uid,
          idToken: user.accessToken,
          photoURL: user.photoURL,
        });
      } else {
        this.props.onHandleSetCurrentUser({
          displayName: {
            username: "",
            id_rol: { value: -1, label: "No match" },
          },
          email: "",
          uid: "",
          idToken: "",
          photoURL: null,
        });
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  render() {
    const { toast } = this.state;
    return (
      <BrowserRouter>
        <Suspense fallback={this.loading}>
          <Switch>
            <PrivateRouter path={urlsAdmin.dashboard} component={Admin} name="Admin" />
            <PrivateRouter path={urlsDigitizer.dashboard} component={Digitizer} name="Digitizer" />
            <Route exact path="/" component={Login} name="Login" />
            <Route exact path="/survey/:rolId/:victimId" component={Survey} />
            <Route component={NotMatch} />
          </Switch>
          <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={5000} />
        </Suspense>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onHandleSetCurrentUser: (value) => dispatch(setCurrentUser(value)),
  onHandleSetDateCurrent: (value) => dispatch(setDateCurrent(value)),
  onHandleSetTheme: (value) => dispatch(setTheme(value)),
  onHandleSetFilterCases: (value) => dispatch(setFilterCases(value)),
  onHandleSetVarsInitials: (value) => dispatch(setVarsInitials(value)),
  onHandleSetLoading: (value) => dispatch(setLoading(value)),
});

export default connect(null, mapDispatchToProps)(App);
