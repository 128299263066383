import React from "react";
import { CTooltip } from "@coreui/react";

const Tooltip = ({
  title,
  description,
  attentionBy,
  subTitle,
  backgroundColor,
  color = "#FFF",
}) => {
  return (
    <CTooltip content={attentionBy}>
      <p
        style={{ backgroundColor, color }}
        className="m-0 px-2 py-1 h7 rounded w-100"
      >
        <b>{title}</b>
        <br />
        <small>{description}</small>
        <br />
        {subTitle}
      </p>
    </CTooltip>
  );
};

export default Tooltip;
