import React from "react";
import { CLabel, CInputRadio } from "@coreui/react";

const RadioButton = ({ className = "form-check-input", classNameGroup, options, name, onChange, disabled, optionSelected }) => {
  const defaultChecked = (label1, label2) => {
    return label2 !== undefined && label1 === label2.label ? true : false;
  };

  return (
    <div variant="checkbox" className={classNameGroup}>
      {options.map((item, index) => (
        <div key={name + index}>
          <CInputRadio
            id={item.label}
            value={item.value}
            className={className}
            onChange={onChange}
            disabled={disabled}
            checked={defaultChecked(item.label, optionSelected)}
          />
          <CLabel variant="checkbox" htmlFor={item.label}>
            {item.label}
          </CLabel>
        </div>
      ))}
    </div>
  );
};

export default RadioButton;
