import React, { useEffect, useCallback, useState, useRef } from "react";
import { CardUserFemale } from "../../molecules";
import { CContainer, CCol, CRow } from "@coreui/react";
import DivTransition from "../../../commons/animations/DivTransition";
import { variantsAdminEvaluations } from "../../../commons/consts/animations";
import { verifyTokenExpired } from "../../../commons/helpers/validations";
import { useSelector } from "react-redux";
import styled from "styled-components";
// Components
import { Toast, Spinner } from "../../atoms";
import { ModalResetToken } from "../../molecules";
// Services
import { getAllUsers } from "../../../commons/services/users";

const AdminEvaluations = () => {
  let timeOut = useRef(null);
  const userCurrent = useSelector((state) => state.currentUser);

  const [modal, setModal] = useState({
    show: false,
    token: false,
    textButtonOnConfrm: "Guardar",
  });
  // States
  const [users, setUsers] = useState({
    data: [],
    spinner: true,
  });

  const [toast, setToast] = useState({
    show: false,
    textContent: "",
    title: "Campos incorrectos",
    className: "bg-danger text-white",
  });

  const onHandleGetAllUsers = useCallback(async () => {
    const { idToken, displayName } = userCurrent;
    try {
      const { success, users, code, message, title } = await getAllUsers({
        idToken,
        id_rol: displayName.id_rol.value,
      });
      if (success) {
        setUsers((prevState) => ({
          ...prevState,
          data: users,
        }));
      } else {
        if (verifyTokenExpired(code)) {
          setModal((prevState) => ({ ...prevState, show: false, token: true }));
        } else {
          setToast((prevState) => ({
            ...prevState,
            show: true,
            title,
            className: "text-white bg-danger",
            textContent: message,
          }));
        }
      }
    } catch (error) {
      setToast((prevState) => ({
        ...prevState,
        show: !toast.show,
        title: "Error: " + error.code,
        textContent: error.message,
        className: "text-white bg-danger",
      }));
    } finally {
      setUsers((prevState) => ({ ...prevState, spinner: false }));
      hiddenToast();
    }
  }, [userCurrent, toast.show]);

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  const hiddenToast = () => {
    // TimeOut for Toast
    timeOut = setTimeout(() => setToast((prevState) => ({ ...prevState, show: false })), 6500);
  };

  const willUnMount = useCallback(() => {
    clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    if (userCurrent.idToken) {
      onHandleGetAllUsers();
    }
    return willUnMount;
  }, [onHandleGetAllUsers, willUnMount, userCurrent.idToken]);

  return (
    <StyledContainer fluid className="rounded-lg px-5 py-4 mb-4">
      <h3 className="mb-4">Evaluaciones de usuarias.</h3>
      <p className="mb-4">
        Las evaluaciones son el apartado donde la administradora puede visualizar la calidad de atención que brinda cada usuaria del sistema.{" "}
        <b>Haga click sobre la usuaria en la cual desea ver su evaluación.</b>
      </p>
      {users.spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <CRow className="justify-content-center">
          {users.data.map((user) => (
            <CCol xs="12" sm="6" md="4" lg="3" key={user.id}>
              <DivTransition variants={variantsAdminEvaluations} initial="hidden" animate="visible" custom={0}>
                <CardUserFemale {...user} />
              </DivTransition>
            </CCol>
          ))}
        </CRow>
      )}
      <Toast show={toast.show} title={toast.title} className={toast.className} textContent={toast.textContent} autohide={6000} />
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
    </StyledContainer>
  );
};

const StyledContainer = styled(CContainer)`
  color: ${(props) => props.theme.tagLineColor};
  transition: all 0.5s ease;
`;

export default AdminEvaluations;
