const urlsDigitizer = {
  dashboard: "/digitizer",
  femicides: "/digitizer/femicidios",
  reports: "/digitizer/reports",
  care_records: "/digitizer/expedientes",
  care_records_survivor: "/digitizer/expedientes/:names/:lastnames/:id/:statusCase",
  care_records_form: "/digitizer/expedientes/form",
};

const urlsAdmin = {
  dashboard: "/admin",
  femicides: "/admin/femicidios",
  reports: "/admin/reportes",
  evaluations: "/admin/evaluaciones",
  evaluationUser: "/admin/evaluaciones/:id",
  users: "/admin/usuarias",
  care_records: "/admin/expedientes",
  care_records_survivor: "/admin/expedientes/:names/:lastnames/:id/:statusCase",
  care_records_form: "/admin/expedientes/form",
  area_emotional: "/admin/area_emocional",
  area_legal: "/admin/area_legal",
  globals: "/admin/globales",
};

// DEV Informed Consent
const urlInformedConsentMayor =
  "https://firebasestorage.googleapis.com/v0/b/saisv-dev.appspot.com/o/documents%2FCONSENTIMIENTOS%20MAYOR%20DE%20EDAD.pdf?alt=media&token=3ad2ff74-08e8-434e-b388-8b80f6859b05";
const urlInformedConsentMenor =
  "https://firebasestorage.googleapis.com/v0/b/saisv-dev.appspot.com/o/documents%2FCONSENTIMIENTO%20MENOR%20DE%20EDAD.pdf?alt=media&token=d8afb138-a4eb-4a83-abc9-a3d249492ce0";

export { urlsDigitizer, urlsAdmin, urlInformedConsentMayor, urlInformedConsentMenor };
