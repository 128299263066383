import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CContainer, CCard, CCardBody, CCardTitle, CCol, CRow, CCardSubtitle } from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";
import { setFilterCases } from "../../../actionsCreators/Global";
import { Button, Toast } from "../../atoms";
import { TableRegister, ModalResetToken } from "../../molecules";
// Static
import { fieldsAtentions } from "../../../commons/static/tables";
import { urlsAdmin, urlsDigitizer } from "../../../commons/consts/urls";
import styled from "styled-components";
// Services
import { getAllCareRecordsList } from "../../../commons/services/carerecords";
// Utils
import { verifyTokenExpired } from "../../../commons/helpers/validations";

const CareRecords = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let timeOut = useRef(null);
  const stateGlobal = useSelector((state) => ({
    idToken: state.currentUser.idToken,
    filterCases: state.filterCases,
    idRol: state.currentUser.displayName.id_rol.value,
  }));
  const { idToken, filterCases } = stateGlobal;

  const [modal, setModal] = useState({
    register: false,
    token: false,
    spinner: false,
  });

  const [state, setState] = useState({
    data: [],
    itemIndex: -1,
    spinner: true,
    paginationTotal: 0,
    filters: filterCases,
  });

  const [toast, setToast] = useState({
    show: false,
    className: "",
    title: "",
    message: "",
  });

  const onChangeInputs = (event, field, arg) => {
    const { target } = event ? event : {};
    switch (arg) {
      case "tableFilterValue":
        if (field === "name") {
          setState((prevState) => ({
            ...prevState,
            filters: { ...state.filters, tableFilterValue: { ...state.filters.tableFilterValue, [field]: event } },
          }));
        } else if (field === "fields-clear") {
          setState(
            (prevState) => ({
              ...prevState,
              filters: { ...state.filters, tableFilterValue: { name: "", startDate: "", endDate: "" } },
              spinner: true,
            }),
            onHandleGetAllCareRecordsList(state.filters.limit, 1, {
              name: "",
              startDate: "",
              endDate: "",
            })
          );
        } else {
          setState((prevState) => ({
            ...prevState,
            filters: {
              ...state.filters,
              tableFilterValue: {
                ...state.filters.tableFilterValue,
                [field]: target.value,
              },
            },
          }));
        }
        break;
      default:
        break;
    }
  };

  const goToCareRecordForm = (item) => {
    if (!item.id) {
      const urlForm = stateGlobal.idRol !== "zLLLhDnXmTG1fOov0oy0" ? urlsAdmin.care_records_form : urlsDigitizer.care_records_form;
      history.push(urlForm);
    } else {
      const { names, lastnames, id, statusCase } = item;
      const url =
        stateGlobal.idRol !== "zLLLhDnXmTG1fOov0oy0"
          ? `${urlsAdmin.care_records}/${names}/${lastnames}/${id}/${statusCase}`
          : `${urlsDigitizer.care_records}/${names}/${lastnames}/${id}/${statusCase}`;
      history.push(url);
    }
  };

  const toggleModalToken = () => {
    setModal((prevState) => ({ ...prevState, token: !modal.token }));
  };

  // Tables
  const onPaginationChange = (limit) => {
    const { filters } = state;
    const { tableFilterValue } = filters;
    setState((prevState) => ({ ...prevState, spinner: true }), onHandleGetAllCareRecordsList(limit, 1, tableFilterValue));
  };

  const onReadySearches = () => {
    const { filters } = state;
    const { tableFilterValue, limit } = filters;
    setState((prevState) => ({ ...prevState, spinner: true }));
    onHandleGetAllCareRecordsList(limit, 1, tableFilterValue);
  };

  const onActivePageChange = (page) => {
    const { filters } = state;
    const { tableFilterValue, limit } = filters;
    setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));
    onHandleGetAllCareRecordsList(limit, page, tableFilterValue);
  };

  const onHandleGetAllCareRecordsList = useCallback(
    async (limit, pagination, tableFilterValue) => {
      try {
        const { success, careRecordsList, title, message, paginationTotal, code } = await getAllCareRecordsList({
          idToken,
          limit,
          pagination,
          tableFilterValue,
        });
        if (success) {
          dispatch(setFilterCases({ limit, pagination, tableFilterValue }));
          setState((prevState) => ({
            ...prevState,
            data: careRecordsList,
            filters: { ...state.filters, limit, pagination, tableFilterValue },
            paginationTotal,
          }));
        } else {
          //
          if (verifyTokenExpired(code)) {
            setModal((prevState) => ({ ...prevState, token: true }));
          } else {
            setToast((prevState) => ({
              ...prevState,
              show: true,
              title,
              message,
              className: "text-whiter bg-danger",
            }));
          }
          //
        }
      } catch (error) {
        setToast((prevState) => ({
          ...prevState,
          show: true,
          title: "Error en getAllCareRecordsList",
          className: "text-white bg-dabger",
          message: error.message,
        }));
      } finally {
        hiddenToast();
        setState((prevState) => ({ ...prevState, spinner: false }));
      }
    },
    [idToken]
  );

  const hiddenToast = () => {
    timeOut = setTimeout(() => {
      setToast((prevState) => ({ ...prevState, show: false }));
    }, 6500);
  };

  const willUnMount = () => {
    clearTimeout(timeOut);
  };

  useEffect(() => {
    if (idToken) {
      onHandleGetAllCareRecordsList(state.filters.limit, state.filters.pagination, state.filters.tableFilterValue);
    }
    return willUnMount;
  }, [onHandleGetAllCareRecordsList, idToken]);

  return (
    <CContainer fluid>
      <StyledCard>
        <CRow className="pt-3 px-3 justify-content-between align-items-center">
          <CCol>
            <CCardTitle className="h2">Expedientes</CCardTitle>
          </CCol>
          <CCol className="text-right">
            <Button
              text="AGREGAR NUEVO"
              //className="mr-2"
              color="success"
              onClick={goToCareRecordForm}
            />
          </CCol>
        </CRow>
        <CRow className="px-3 pt-3">
          <CCol>
            <CCardSubtitle className="h5">
              La modalidad de expedientes conlleva todos los datos de denuncias de mujeres sobrevivientes de violencias en contra de
              parientes o no parientes masculinos, además adjunta por cada expediente; citas, seguimientos, acompañamientos, progreso del
              caso, bitácoras y planes terapéuticos aplicados asi como también recursos empleados para la atención emocional y/o legal.
            </CCardSubtitle>
          </CCol>
        </CRow>
        <CCardBody>
          <TableRegister
            disableDelete
            elements={state}
            fields={fieldsAtentions}
            toggleModal={goToCareRecordForm}
            onPaginationChange={onPaginationChange}
            onTableFilterChange={onChangeInputs}
            onActivePageChange={onActivePageChange}
            onReadySearches={onReadySearches}
          />
        </CCardBody>
      </StyledCard>
      <ModalResetToken show={modal.token} spinner={modal.spinner} setModal={setModal} toggleModal={toggleModalToken} />
      <Toast show={toast.show} title={toast.title} textContent={toast.message} className={toast.className} autohide={6000} />
    </CContainer>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default CareRecords;
