import React from "react";
import { CContainer, CCol, CRow } from "@coreui/react";
import { DateTimePicker } from "../../molecules";
import { Input, Label, Dropdown, TextArea, Imagen, CheckBoxes, Spinner } from "../../atoms";
import { useSelector } from "react-redux";

const TestAppQuoteForm = ({ paraments, onChangeInputs, controllers }) => {
  const stateGlobal = useSelector((state) => ({
    photoUser: state.currentUser.photoURL,
    dateCurrent: state.dateCurrent,
    displayName: state.currentUser.displayName,
    attentionMode: state.attentionMode,
    virtualOptions: state.virtualOptions,
  }));
  const { displayName, attentionMode, virtualOptions } = stateGlobal;
  const {
    names,
    lastnames,
    age,
    dateTestApp,
    hourTestApp,
    queryReasonTestApp,
    appliedTests,
    observationsTestApp,
    resultsTestApp,
    attentionModeTestApp,
    nextQuoteDate,
    nextQuoteHourStart,
    nextQuoteHourEnd,
    virtualOptionsTestApp,
    photoUser,
    attentionBy,
  } = paraments;
  return (
    <CContainer fluid className="py-4 rounded-lg">
      {controllers.spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <CRow>
          <CCol xs="12" lg="6">
            <Label text="1. Fecha y hora" isRequeried className="h5" />
            <DateTimePicker
              disabled
              disableTime2
              valueDate={dateTestApp}
              valueTime={hourTestApp}
              onChangeDate={(e) => onChangeInputs(e, "dateTestApp", "input")}
              onChangeTime={(e) => onChangeInputs(e, "hourTestApp", "input")}
              maxDate={stateGlobal.dateCurrent}
            />
            <Label text="2. Nombres" isRequeried className="h5 mt-3" />
            <Input type="text" value={names} disabled />
            <Label text="3. Apellidos" isRequeried className="h5 mt-3" />
            <Input type="text" value={lastnames} disabled />
            <Label text="4. Edad" isRequeried className="h5 mt-3" />
            <Input type="number" classNameInput="w-75" value={age} disabled />
            <Label text="5. Motivo de la consulta" isRequeried className="h5 mt-3" />
            <TextArea
              maxLength={3000}
              height={150}
              value={queryReasonTestApp}
              onChange={(e) => onChangeInputs(e, "queryReasonTestApp", "input")}
            />
            {/* <Button text="Creación de consentimiento informado" color="info" className="w-100" /> */}
            <Label text="6. Pruebas aplicadas" isRequeried className="h5 mt-3" />
            <TextArea maxLength={3000} height={150} value={appliedTests} onChange={(e) => onChangeInputs(e, "appliedTests", "input")} />
          </CCol>
          <CCol xs="12" lg="6">
            <Label text="7. Observaciones" isRequeried className="h5 mt-3" />
            <TextArea
              maxLength={3000}
              height={150}
              value={observationsTestApp}
              onChange={(e) => onChangeInputs(e, "observationsTestApp", "input")}
            />
            <Label text="8. Resultados obtenidos" isRequeried className="h5 mt-3" />
            <TextArea maxLength={3000} height={150} value={resultsTestApp} onChange={(e) => onChangeInputs(e, "resultsTestApp", "input")} />
            <Label text="9. Agenda próxima cita" className="h5" isRequeried />
            <DateTimePicker
              valueDate={nextQuoteDate}
              valueTime={nextQuoteHourStart}
              valueTime2={nextQuoteHourEnd}
              onChangeDate={(e) => onChangeInputs(e, "nextQuoteDate", "input")}
              onChangeTime={(e) => onChangeInputs(e, "nextQuoteHourStart", "input")}
              onChangeTime2={(e) => onChangeInputs(e, "nextQuoteHourEnd", "input")}
              minDate={stateGlobal.dateCurrent}
            />
            <Label text="10. Modalidad de atención" className="h5 mt-3" isRequeried />
            <Dropdown
              data={attentionMode}
              color={attentionModeTestApp.value !== -1 ? "purple" : "secondary"}
              onClick={(e) => onChangeInputs(e, "attentionModeTestApp", "dropdown")}
              placeholder={attentionModeTestApp.label}
            />
            {attentionModeTestApp.value === "R925ema5ejFwt1y4HICn" && (
              <div className="bg-light rounded p-3 my-3 text-dark">
                <Label isRequeried text="10.1 - Elegir el o los tipos de modalidades virtuales" />
                <CheckBoxes
                  name="virtualOptionsTestApp"
                  list={virtualOptions}
                  checked={virtualOptionsTestApp}
                  onChange={(e) => onChangeInputs(e, "virtualOptionsTestApp", "check-box")}
                />
              </div>
            )}
            <h5 className="mt-3">Atendida por:</h5>
            <div className="d-flex flex-row align-items-center">
              <Imagen src={photoUser || stateGlobal.photoUser} width={80} height={80} className="rounded-circle" />
              <p className="h5 font-italic ml-2">{attentionBy || displayName.username}</p>
            </div>
          </CCol>
        </CRow>
      )}
    </CContainer>
  );
};

export default TestAppQuoteForm;
