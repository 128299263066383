import React from "react";
import { CHeaderNavItem, CHeaderNavLink } from "@coreui/react";

const NavLink = ({ title, className, to, icon, onClick }) => {
  return (
    <CHeaderNavItem
      className={className}
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <CHeaderNavLink to={to} onClick={onClick}>
        {title || icon}
      </CHeaderNavLink>
    </CHeaderNavItem>
  );
};

export default NavLink;
