import React from "react";
import { CCard, CCardBody, CCardFooter, CCardHeader, CCardText, CCardTitle, CCol, CRow } from "@coreui/react";
import { Imagen } from "../../atoms";
import logo_brand from "../../../assets/logo_brand.svg";

const CardBanner = () => {
  return (
    <CCard className="bg-primary p-0 m-0">
      <CCardHeader className="h1 font-weight-bold text-center">SAISV</CCardHeader>
      <CCardBody>
        <CRow className="text-center">
          <CCol xs="12">
            <CCardTitle className="h2 text-center">Visión</CCardTitle>
            <CCardText className="text-justify">
              Ser una organización feminista sólida, referente en la promoción, incidencia y defensa de los DDHH de las mujeres, para su
              empoderamiento y reconocimiento como sujetas de derecho.
            </CCardText>
            <CCardTitle className="h2 text-center">Misión</CCardTitle>
            <CCardText className="text-justify">
              Somos una organización feminista sororaria y comprometida con la reivindicación de la dignidad, la justicia y el respeto a los
              derechos humanos de las mujeres, sin distingo de edad, etnia, religión, preferencia sexual, clase social y discapacidad,
              brindando asistencia a las víctimas/sobrevivientes de violencias, mediante un modelo de atención integral centrado en el
              cambio de las relaciones de poder que someten y oprimen a las mujeres.
            </CCardText>
          </CCol>
          <CCol className="align-self-center pt-3" xs="12">
            <Imagen src={logo_brand} height={130} />
            <CCardFooter className="bg-primary p-0 m-0" style={{ borderTopWidth: 0 }}>
              <div className="d-flex flex-column align-items-center bg-primary">
                <p className="h6 text-white rounded-lg font-weight-bold text-center py-2 w-50">DESDE 1984</p>
              </div>
            </CCardFooter>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default CardBanner;
