import { URL, buildRequest } from "../../config/config";

const registerFemicide = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en registerFemicide",
  };
  try {
    const responseFetch = await fetch(URL + "registerFemicide", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const getAllListFemicides = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getAllListFemicides",
    femicides: [],
  };
  try {
    const responseFetch = await fetch(URL + "getAllListFemicides", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const getViolences = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getViolences",
    violences: [],
  };
  try {
    const responseFetch = await fetch(URL + "getViolences", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const getOccupations = async (data) => {
  let response = {
    success: false,
    message: "Error en la petición.",
    title: "Error en getOccupations",
    occupations: [],
  };
  try {
    const responseFetch = await fetch(URL + "getOccupations", buildRequest(data));
    response = await responseFetch.json();
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const updateFemicide = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en updateFemicide",
  };
  try {
    const responseFetch = await fetch(URL + "updateFemicide", buildRequest(data));
    const json = responseFetch.json();
    response = json;
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const getFemicide = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getFemicide",
    femicide: {},
  };
  try {
    const responseFetch = await fetch(URL + "getFemicide", buildRequest(data));
    const json = responseFetch.json();
    response = json;
  } catch (error) {
    response.message = error.message;
  } finally {
    return response;
  }
};

const deleteFemicide = async (data) => {
  let response = {
    sucess: false,
    message: "Error en la petición.",
    title: "Error en getFemicide",
  };
  try {
    try {
      const responseFetch = await fetch(URL + "deleteFemicide", buildRequest(data));
      const json = responseFetch.json();
      response = json;
    } catch (error) {
      response.message = error.message;
    } finally {
      return response;
    }
  } catch (error) {}
};

export { registerFemicide, getAllListFemicides, getOccupations, getFemicide, updateFemicide, deleteFemicide, getViolences };
