import React from "react";
import { CContainer, CCol, CRow, CSpinner } from "@coreui/react";
import { DateTimePicker, ListGroup, CheckBoxesMultiple } from "..";
import { Input, Dropdown, TextArea, Label, RadioButton, Icon, SelectInput, LabelWarning, Spinner, CheckBoxes } from "../../atoms";
import { useSelector } from "react-redux";
// RegExp
import { ageReg, nameOrLastnameReg } from "../../../commons/consts/regularexp";
import { validInputReExp } from "../../../commons/helpers/validations";

const options = [
  { value: 0, label: "NO" },
  { value: 1, label: "SI" },
];

const optionsZone = [
  { value: 0, label: "RURAL" },
  { value: 1, label: "URBANA" },
];

const FemicideForm = ({ states, paraments, onChangeInputs, deleteItem, onInputSelectChange, loadOptionsSelectInput }) => {
  const datasDropdown = useSelector((state) => ({
    maritalStatus: state.maritalStatus,
    causes: state.causes,
    ethnicities: state.ethnicities,
    sexualOrientations: state.sexualOrientations,
    aggressorTypes: state.aggressorTypes,
    weaponTypes: state.weaponTypes,
    dateCurrent: state.dateCurrent,
    states: state.states,
    medios: state.medios,
    violenceTypes: state.violenceTypes,
  }));
  const { spinnerRegion, regions, spinner, loadingSelectInput } = states;
  const {
    firstname,
    secondname,
    lastname,
    secondlastname,
    age,
    occupation,
    sexualOrientationId,
    maritalStatusId,
    ethnicityId,
    pregnancy,
    // Medio
    medioId,
    woundsNumber,
    weaponId,
    causeId,
    aggressorsNumber,
    aggressorTypeId,
    violenceTypes,
    // Place
    headline,
    resumen,
    date,
    time,
    stateId,
    regionId,
    place,
    zone,
  } = paraments;
  return (
    <>
      {spinner ? (
        <div>
          <Spinner />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <CContainer fluid className="form">
          <p className="h6">
            Formulario para agregar o editar un caso de femicidio, recuerde llenar los campos requeridos. Entre más completo y legibles sean
            los datos, más acertado será el análisis estadístico.
          </p>
          <div className="d-flex flex-row justify-content-end">
            <Label className="font-bold text-danger" text="Campo requerido" isRequeried />
            <Icon name="handPointLeft" className="ml-1" size="2x" />
          </div>
          <CRow className="justify-content-center">
            <CCol xs="12" sm="6">
              <div className="mt-4">
                <Label text="Datos personales" className="h4 font-semiBold" />
              </div>
              <Label className="mt-3 h5" text="Primer nombre" isRequeried />
              <Input
                type="text"
                noAutoComplete="true"
                value={firstname}
                onChange={(e) => onChangeInputs(e, "firstname", "input")}
                classNameInput={validInputReExp(firstname, nameOrLastnameReg)}
              />
              <Label className="mt-3 h5" text="Segundo nombre" />
              <Input
                type="text"
                value={secondname}
                noAutoComplete="true"
                onChange={(e) => onChangeInputs(e, "secondname", "input")}
                classNameInput={validInputReExp(secondname, nameOrLastnameReg)}
              />
              <Label className="mt-3 h5" text="Primer apellido" isRequeried />
              <Input
                type="text"
                value={lastname}
                noAutoComplete="true"
                onChange={(e) => onChangeInputs(e, "lastname", "input")}
                classNameInput={validInputReExp(lastname, nameOrLastnameReg)}
              />
              <Label className="mt-3 h5" text="Segundo apellido" />
              <Input
                type="text"
                value={secondlastname}
                noAutoComplete="true"
                onChange={(e) => onChangeInputs(e, "secondlastname", "input")}
                classNameInput={validInputReExp(secondlastname, nameOrLastnameReg)}
              />
              <CRow className="justify-content-between px-3 align-items-end">
                <Label className="mt-3 h5" text="Edad" isRequeried />
                <LabelWarning text="DESCONOCIDA = -1" iconName="handPointRight" iconColor="#5e039b" />
              </CRow>
              <Input
                type="number"
                min={-1}
                max={120}
                value={age}
                onChange={(e) => onChangeInputs(e, "age", "number")}
                classNameInput={validInputReExp(age, ageReg)}
              />
              <Label className="mt-3 h5" text="Ocupación" isRequeried />
              <SelectInput
                isLoading={loadingSelectInput}
                className="w-75"
                value={occupation}
                onChange={(e) => onChangeInputs(e, "occupation", "select-input")}
                loadOptions={loadOptionsSelectInput}
                onInputChange={onInputSelectChange}
              />
              <Label className="mt-3 h5" text="Orientación sexual" isRequeried />
              <Dropdown
                className="w-75"
                data={datasDropdown.sexualOrientations}
                placeholder={sexualOrientationId.label}
                color={sexualOrientationId.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "sexualOrientationId", "dropdown")}
              />
              <Label className="mt-3 h5" text="Estado civil" isRequeried />
              <Dropdown
                className="w-75"
                data={datasDropdown.maritalStatus}
                color={maritalStatusId.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "maritalStatusId", "dropdown")}
                placeholder={maritalStatusId.label}
              />
              <Label className="mt-3 h5" text="Etnia" isRequeried />
              <Dropdown
                className="w-75"
                data={datasDropdown.ethnicities}
                color={ethnicityId.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "ethnicityId", "dropdown")}
                placeholder={ethnicityId.label}
              />
              <Label className="mt-3 h5" text="Embarazo" isRequeried />
              <RadioButton
                name="Confirmations"
                options={options}
                optionSelected={options[pregnancy.value]}
                onChange={(e) => onChangeInputs(e, "pregnancy", "radio-button")}
                classNameGroup="ml-4"
              />
            </CCol>
            <CCol xs="12" sm="6">
              <div className="mt-4">
                <Label text="Titular y lugar" className="h4 font-semiBold" />
              </div>
              <Label className="mt-3 h5" text="Titular de la noticia" isRequeried />
              <Input type="text" value={headline} onChange={(e) => onChangeInputs(e, "headline", "input")} />
              <Label className="mt-3 h5" text="Resumen" isRequeried />
              <TextArea maxLength={3000} height={180} value={resumen} onChange={(e) => onChangeInputs(e, "resumen", "input")} />
              <Label className="mt-3 h5" text="Fecha y hora" isRequeried />
              <DateTimePicker
                valueDate={date <= datasDropdown.dateCurrent ? date : datasDropdown.dateCurrent}
                valueTime={time}
                onChangeDate={(e) => onChangeInputs(e, "date", "input")}
                onChangeTime={(e) => onChangeInputs(e, "time", "input")}
                maxDate={datasDropdown.dateCurrent}
                disableTime2
              />
              <Label className="mt-3 h5" text="Departamento" isRequeried />
              <Dropdown
                className="w-75"
                data={datasDropdown.states}
                color={stateId.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "stateId", "dropdown")}
                placeholder={stateId.label}
              />
              <Label className="mt-3 h5" text="Municipio" isRequeried />
              <LabelWarning text="Recuerde seleccionar primero el departamento." />
              {spinnerRegion ? (
                <div className="row w-100 justify-content-center mt-2">
                  <CSpinner color="success" />
                </div>
              ) : (
                <Dropdown
                  className="w-75"
                  data={regions}
                  color={regionId.value !== -1 ? "purple" : "secondary"}
                  onClick={(e) => onChangeInputs(e, "regionId", "dropdown")}
                  placeholder={regionId.label}
                  disabled={stateId.value !== -1 ? false : true}
                />
              )}

              <Label className="mt-3 h5" text="Lugar" />
              <Input type="text" value={place} onChange={(e) => onChangeInputs(e, "place", "input")} />
              <Label className="mt-3" text="Zona" isRequeried />
              <RadioButton
                name="zones"
                options={optionsZone}
                optionSelected={optionsZone[zone.value]}
                onChange={(e) => onChangeInputs(e, "zone", "radio-button")}
                classNameGroup="ml-4"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12" sm="6">
              <div className="mt-4">
                <Label text="Datos noticiosos" className="h4 font-semiBold" />
              </div>
              <ListGroup
                list={datasDropdown.medios}
                title="Medios de comunicación"
                onClick={(e) => onChangeInputs(e, "medioId", "list-group")}
                placeholder={medioId.selected}
                itemsSelected={medioId.items}
                itemDelete={(e) => deleteItem(e, "medioId")}
              />
              <Label className="mt-3 h5" text="Arma" isRequeried />
              <Dropdown
                className="w-75"
                data={datasDropdown.weaponTypes}
                color={weaponId.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "weaponId", "dropdown")}
                placeholder={weaponId.label}
              />
              <CRow className={"justify-content-between px-3 align-items-end"}>
                <Label className="mt-3 h5" text="Número de agresores" />
                <LabelWarning text="DESCONOCIDO = -1" iconName="handPointRight" iconColor="#5e039b" />
              </CRow>
              <Input type="number" min={-1} value={aggressorsNumber} onChange={(e) => onChangeInputs(e, "aggressorsNumber", "number")} />
            </CCol>
            <CCol xs="12" sm="6">
              <CRow className="justify-content-between px-3 align-items-end">
                <Label className="mt-5 h5" text="Heridas" />
                <LabelWarning text="DESCONOCIDA = -1" iconName="handPointRight" iconColor="#5e039b" />
              </CRow>
              <Input type="number" min={-1} value={woundsNumber} onChange={(e) => onChangeInputs(e, "woundsNumber", "number")} />
              <Label className="mt-3 h5" text="Posible móvil" isRequeried />
              <Dropdown
                className="w-75"
                data={datasDropdown.causes}
                color={causeId.value !== -1 ? "purple" : "secondary"}
                onClick={(e) => onChangeInputs(e, "causeId", "dropdown")}
                placeholder={causeId.label}
              />
              <Label className="mt-3 h5" text="Tipo de agresores" isRequeried />
              <CheckBoxes
                name="aggressorTypes"
                classNameGroup="ml-4"
                list={datasDropdown.aggressorTypes}
                checked={aggressorTypeId}
                onChange={(e) => onChangeInputs(e, "aggressorTypeId", "check-boxes")}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CheckBoxesMultiple
                classNameTitle="h5"
                data={datasDropdown.violenceTypes}
                title="Tipos de violencias"
                subTitle="Violencias agregadas"
                onChange={onChangeInputs}
                controller={violenceTypes}
                field="violenceTypes"
              />
            </CCol>
          </CRow>
        </CContainer>
      )}
    </>
  );
};

export default FemicideForm;
