import React from "react";
import { CTextarea } from "@coreui/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TextArea = ({ placeholder, onChange, className, disabled, maxLength, height, value, size = "md" }) => {
  const theme = useSelector((state) => state.theme);
  const getTheme = () => (theme === "dark" ? "text-white bg-info" : "text-dark bg-white");
  return (
    <div className={`text-right ${className}`}>
      <CTextarea
        style={{ height }}
        placeholder={placeholder}
        size={size}
        value={value}
        onChange={onChange}
        className={getTheme()}
        disabled={disabled}
        maxLength={maxLength}
      />
      {value.length}/{maxLength}
    </div>
  );
};

TextArea.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.string,
};

TextArea.defaultProps = {
  onChange: () => {},
  placeholder: "Contenido",
  disabled: false,
  maxLength: 1000,
  height: 150,
  value: "",
};

export default TextArea;
