import React from "react";
import { CRow, CCard } from "@coreui/react";
import { ChartLineSimple, Spinner } from "../../atoms";
import { colorsBadged } from "../../../commons/consts/colors";
import styled from "styled-components";

const ChartLineCard = ({ titleLitte, title, footerText, dataPoints, labels, label, nTotal = 0, loading, max, min = 0 }) => {
  return (
    <StyledCard className="p-4">
      <CRow>
        <h3 className="text-purple">{titleLitte}</h3>
        <div className="line-vertical" />
        <h4 className="w-75 pt-1">{title}</h4>
      </CRow>
      {loading ? (
        <div>
          <Spinner width={230} height="auto" />
          <p className="h4 font-regular text-center">Espere un momento por favor...</p>
        </div>
      ) : (
        <div className="chart-wrapper mt-3 text-right">
          <h5>TOTAL={nTotal}</h5>
          <ChartLineSimple
            style={{ height: "250px" }}
            dataPoints={dataPoints}
            labels={labels}
            label={label}
            borderColor={dataPoints ? colorsBadged(1) : colorsBadged(10)}
            max={max}
            min={min}
          />
        </div>
      )}
      <small className="mt-4">{footerText}</small>
    </StyledCard>
  );
};

const StyledCard = styled(CCard)`
  color: ${(props) => props.theme.tagLineColor};
  background-color: ${(props) => props.theme.pageBackground};
  transition: all 0.5s ease;
`;

export default ChartLineCard;
