import React from "react";
import { CTabPane } from "@coreui/react";
import { TabCategories } from "..";

const Tabs = ({
  state,
  paraments,
  showModal,
  toggleModal,
  fields,
  onChangedEnabledDisabled,
  onChangeInputs,
  handleOnConfirm,
  onPaginationChange,
  onActivePageChange,
  onReadySearches,
}) => {
  return (
    <CTabPane data-tab={state.tabCurrent}>
      <TabCategories
        state={state}
        paraments={paraments}
        fields={fields}
        onPaginationChange={onPaginationChange}
        onActivePageChange={onActivePageChange}
        onChangeInputs={onChangeInputs}
        onChangedEnabledDisabled={onChangedEnabledDisabled}
        showModal={showModal}
        toggleModal={toggleModal}
        handleOnConfirm={handleOnConfirm}
        onReadySearches={onReadySearches}
      />
    </CTabPane>
  );
};

export default Tabs;
