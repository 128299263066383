import React from "react";
import { CCol, CRow, CForm } from "@coreui/react";
import { Input, Button, Toast, Spinner, NavLink } from "../../atoms";

const LoginForm = ({ state, toast, onChangeText, actionLogin, onHandleShowPass, toggleModalForgottenPass }) => {
  return (
    <CForm onSubmit={actionLogin}>
      <h1 className="font-black">Inicio de sesión</h1>
      <p className="text-muted mb-5">Digite su correo y contraseña.</p>
      <Input
        placeholder="Correo"
        className="mb-3"
        icon="userFemale"
        type="text"
        value={state.email}
        onChange={(e) => onChangeText(e, "email")}
        name="email"
        classNameInput="h5"
      />
      <CRow>
        <CCol xs="10">
          <Input
            placeholder="Contraseña"
            className="mb-2"
            icon="locked"
            type={state.showPass}
            value={state.password}
            onChange={(e) => onChangeText(e, "password")}
            classNameInput="h5"
          />
        </CCol>
        <CCol xs="1">
          <Button icon={state.iconShowPass} className="mb-3 p-1" color="danger" onClick={onHandleShowPass} size="lg" type="button" />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs="12">
          {state.showSpinner ? (
            <Spinner width={80} height={80} />
          ) : (
            <Button
              text="Ingresar"
              className="w-100 font-bold text-white"
              color="primary"
              onClick={actionLogin}
              disabled={toast.show}
              size="lg"
              type="submit"
            />
          )}
        </CCol>
        <CCol xs="12" className="text-right mt-1">
          <u>
            <NavLink title="¿Ha olvidado su contraseña?" className="h6 font-italic" onClick={toggleModalForgottenPass} />
          </u>
        </CCol>
      </CRow>
      <Toast show={toast.show} title={toast.title} textContent={toast.textContent} className={toast.className} />
    </CForm>
  );
};

export default LoginForm;
